import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  Button,
  CardContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchUsers } from 'src/actions/idActions';
import Results from './Result';
import { fetchCategory, fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import { fetchAttribute } from 'src/actions/mgmtAttibuteAction';
import { fetchAdminList } from 'src/actions/mgmtAdminAction';
import Header from './Header';
import MperitoCard from 'src/components/MperitoCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function AdminView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const adminList = useSelector((state) => state?.admin?.adminList);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    dispatch(fetchAdminList(businessInfo?.customerId));
  }, [businessInfo]);

  return (
    <Page
      className={classes.root}
      title="Admin List"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
        <br />
        <MperitoCard title={`Admin Panel Users ( ${businessInfo?.businessName} - ${businessInfo?.customerId} )`}>
          <Results adminList={adminList} customerId={businessInfo?.customerId} />
        </MperitoCard>
      </Container>
    </Page>
  );
}

export default AdminView;

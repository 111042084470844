import React, {
  useEffect,
} from 'react';
import { useLocation } from "react-router-dom";
import {
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
// import Results from './Result';
import { fetchAdminCustomerList, fetchCustomerList } from 'src/actions/mgmtCustomerCreateAction';

import UserEditForm from 'src/views/users/UserEdit/UserEditForm';
import RegisterRequestEditForm from './RegisterRequestEdit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function RegisterRequestEdit() {
  // const classes = useStyles();
  
  const { state } = useLocation();
  const dispatch = useDispatch();
  const path = process.env.REACT_APP_PATH
  const classes = useStyles();

  const customerInfo = useSelector(
    (state) => state?.customer?.customerInfoList
  );

  const customerAdminInfo = useSelector(
    (state) => state?.customer?.customeradminInfoList
  );

  const customer = useSelector(
    (state) => state?.account?.user?.userType
  );

  useEffect(() => {
     console.log('State passed via navigation:', state);
    dispatch(fetchCustomerList(cid, customer));
  }, []);

  const customerId = useSelector(
    (state) => state?.account?.user?.customerId
  );

  const customer15 = useSelector(
    (state) => state?.user?.users1
  );

  const selectedCustomerId = (customer15) ? customer15 : customer15;

  const cid = (customer === "SUPER_ADMIN") ? selectedCustomerId : customerId;

 
  useEffect(() => {
    if(cid > 0){
    dispatch(
      fetchAdminCustomerList(cid));
    }
  }, []);


  const isDisabled = customer === 'ADMIN';
   useEffect(() => {
    if(cid > 0){
    dispatch(
      fetchAdminCustomerList(cid));
    }
  }, []);

  const distributorCategoryList = useSelector(
    (state) => state?.user?.distributorCategory
  );

  return (
    <Page
      className={classes.root}
      title="Customer List"
    >
      <Container maxWidth={false}>
        <Header isDisabled={isDisabled} />
        
        <br />
        <RegisterRequestEditForm  state={state} distributorCategoryList={distributorCategoryList}/>
        {/* <Results services={customerInfo} services1={customerAdminInfo}  path={path} customer={customer} isDisabled={isDisabled} /> */}
      </Container>
    </Page>
  );
}

export default RegisterRequestEdit;
import React, { useState, useEffect, } from 'react'
import { DataGrid, } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, IconButton, SvgIcon, Tooltip } from '@mui/material';
import Delete from '@mui/icons-material/Delete';

import {
  Edit as EditIcon,
} from 'react-feather';
import { fetchAttribute, deleteAttribute } from 'src/actions/mgmtAttibuteAction';


function Results({ className, attributes, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {

  }, [attributes]);

  const handleDelete = async (customerId, attributeId) => {

    try {
      await dispatch(deleteAttribute(customerId, attributeId));  
      dispatch(fetchAttribute(customerId));
    } catch (err) { }
  };

  const columns = [
    { field: 'customerId', headerName: 'Customer Id', width: 100 },
    { field: 'attributeId', headerName: 'Attribute Id', width: 100 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'type', headerName: 'Type', width: 100 },
    { field: 'isFilterable', headerName: 'Is Filterable', width: 100 },
    { field: 'isSearchable', headerName: 'Is Searchable', width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Edit Attribute">
              <IconButton
                onClick={() => {
                  navigate('/app/management/attribute/edit', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <m>
              <Tooltip title="Delete Attribute">
                <IconButton
                  onClick={() => handleDelete(params.row.customerId, params.row.attributeId)}
                >
                  <SvgIcon fontSize="small">
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip></m>
          </div>
        );
      },
    },
  ]







  return (
    <Grid container sx={{ height: 500, width: '100%', margin: 4, mt: 2 }}>
      <DataGrid
        rows={attributes}
        columns={columns}
        getRowId={(row) => row.attributeId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Grid>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  attributes: PropTypes.array
};

Results.defaultProps = {
  attributes: []
};

export default Results;
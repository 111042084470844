
import { FETCH_REGISTER_BUSINESS } from 'src/constants';
import axios from 'src/utils/axios';

class MgmtRegister {
  
  fetchRegisteredBusinessData = () => new Promise((resolve, reject) => {
    axios
      .get(FETCH_REGISTER_BUSINESS) // Assuming FETCH_BUSINESS_LIST contains the URL 'http://52.151.197.88/api/inquiry/register/all'
      .then((response) => {
        if (response.data && response.data.data && response.data.data.registeredBusiness) {
          // let businessData = response.data.data; // Adjust based on the actual structure of the response
          // resolve(businessData);
           resolve(response.data.data.registeredBusiness);
        } else {
          // reject(response.data.error);
           resolve(response.data.data.registeredBusiness);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}

const mgmtRegister = new MgmtRegister();
export default mgmtRegister;
/* eslint-disable import/prefer-default-export */
export const THEMES = {
  DEFAULT: "DEFAULT",
  MPERITO_DEFAULT: "MPERITO_DEFAULT",
};

export const USER_ROLES = {
  ADMIN: "ADMIN",
  MARKETING: "MARKETING",
};

export const ORDER_DOC_TYPES = {
  ORDER: 'Order',
  INVOICE: 'Invoice',
  DELIVERY_CHALLAN: 'Delivery Challan',
};

export const ORDER_STATUS = [
  { value: 'CREATED', label: 'Created' }, 
  { value: 'PAID', label: 'Paid' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  // { value: 'IN_TRANSIT', label: 'In Transit' }, TODO_SP: To be used separately
  { value: 'SHIPPED', label: 'Shipped' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'CANCELLED', label: 'Cancelled' },
  // { value: 'ON_HOLD', label: 'On Hold' }, TODO_SP: To be used separately
];

export const DASHBOARD_SUMMARY_TYPES = {
  TOTAL: "TOTAL",
  YEARLY: "YEARLY",
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
  TODAY: "TODAY",
};

export const SIGNUP_URL = "/api/auth/admin/signup";
export const SIGNIN_URL = "/api/auth/admin/signin";
export const SIGNOUT_URL = "/api/auth/admin/signout";
export const GET_USER_PROFILE_URL = "/api/auth/admin/my/profile";
export const CHANGE_PASSWORD = "/api/auth/change-password";
export const UPDATE_PROFILE = "/api/auth/update-profile";
export const CREATE_PROJECT_URL = "/api/project/create";

export const DASHBOARD_OVERVIEW = "/api/dashboard/getUserOverview";
export const DASHBOARD_SUPLIERS = "/api/dashboard/supplier/getTotalSupplier";
export const DASHBOARD_REQUIREMENTS = "api/dashboard/rfqs/getTotalRfqs";
export const DASHBOARD_SERVICE = "api/dashboard/supplier/getTotalServices";
export const DASHBOARD_LASTWEEKUSER = "api/dashboard/getLastWeekUser";
export const DASHBOARD_LASTMONTHUSER = "api/dashboard/getLastMonthUser";
export const DASHBOARD_USERCLASSIFICATION = "api/dashboard/userClassification";
export const SUPLIER_SERVICES="api/dashboard/supplier/getTotalLocation"
export const SUPLIER_PER_CATEGORY="api/dashboard/supplier/getTotalSupplPerCate";

export const FETCH_BUSINESS_LIST = "/api/admin/customer/ids";
export const FETCH_REGISTER_BUSINESS= "/api/inquiry/register/all"; 
export const UPDATE_REGISTER_BUSINESS= "/api/inquiry/register"; //new code
// Search
export const TOTAL_REQUEST_TILL_NOW="api/dashboard/search/getTotalSearchReq"
export const TOTAL_UNIQUE_KEYWORDS="api/dashboard/search/getUniqueWordsSearch"
export const TOTAL_LAST_WEEK_SEARCH_TRENDS="api/dashboard/search/getLastWeekSearchTrend"
export const TOTAL_LAST_MONTH_SEARCH_TRENDS="api/dashboard/search/getLastMonthSearchTrend"
export const TOTAL_LAST_WEEK_SEARCH_DATA="api/dashboard/search/getLastWeekSearch"
export const TOTAL_LAST_MONTH_SEARCH_DATA="api/dashboard/search/getLastMonthSearch"

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Box,IconButton,SvgIcon } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';

import VisibilityIcon from '@mui/icons-material/Visibility';

import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import DownloadIcon from '@mui/icons-material/Download';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';

import {
  Edit as EditIcon,

} from 'react-feather';
import { useNavigate } from 'react-router-dom';
function Results({ className, adminList, ...rest }) {
   
  const navigate = useNavigate();
  const columns = [
    { field: 'businessName', headerName: 'Business Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 340,
      renderCell: (params) => {
        const paymentType = params.row.paymentGateway
        const paymentTypeInfo = paymentType
        return (
          <div className="cellAction">
           
            <Tooltip title="Edit Customer Brand Details">
              <IconButton
                onClick={() => {
                     navigate('/app/management/customer/create', { state: params.row });
                //  /app/management/customer/registerRequestCreate
                }
                }
              >
                <SvgIcon fontSize="small">
                 <PersonIcon />
                 
                </SvgIcon>
              </IconButton>
            </Tooltip>
           
            <Tooltip title="Edit Customer Details">
              <IconButton
                onClick={() => {
                  navigate('/app/management/register/update', { state: params.row });
                }
                }
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    { field: 'mobile', headerName: 'Mobile', width: 100 },
    { field: 'businessType', headerName: 'Business Type', width: 150 },
    { field: 'requestedFrom', headerName: 'Requested From', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  return (
    <Box sx={{ height: 400, width: '100%', padding: 2 }}>
      <DataGrid
        rows={adminList}
        columns={columns}
        getRowId={(row) => row.businessId} // Unique identifier for each row
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  adminList: PropTypes.array,
};

Results.defaultProps = {
  adminList: [],
};

export default Results;
import React, { useState , useEffect} from 'react';
import {
  Box, Button, Card, CardContent, Typography, CardHeader, Divider,
  Grid, TextField, MenuItem, Select, InputLabel, FormControl, FormHelperText,IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { Formik,FieldArray } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import axios from 'src/utils/axios';

// Define your custom styles using makeStyles
const useStyles = makeStyles(() => ({
  root: {},
}));

// Define your main DeliveryCharges component
function DeliveryCharges({ className, state, deliverychargesInfo, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // State variables to track which fields to show based on delivery type
  const [showOrderField, setShowOrderField] = useState(false);
  const [showLocationField, setShowLocationField] = useState(false);
  const [showDistanceField, setShowDistanceField] = useState(false);
   const [deliveryOptions, setDeliveryOptions] = useState({});
   const [initialFormData, setInitialFormData] = useState({
  customerId: state.customerId || '',
  deliveryType: '',
  orders: [{ minOrderValue: '', maxOrderValue: '', charge: '' }],
  locations: [{ type: '', charge: '' }],
  distances: [{ minDistance: '', maxDistance: '', charge: '' }]
});
   // Handler to close the container
const handleClose = () => {
  setShowOrderField(false);
};
const handleLocationClose = () => {
  setShowLocationField(false);
};
const handleDistanceClose = () => {
  setShowDistanceField(false);
};
    useEffect(() => {
    //    axios.get(`/api/order/delivery-charges?customerId=${state.customerId}`)
    // .then(response => {
    //   if (!response.data.error) {
    //     // Log the delivery charges response to the console
    //     console.log('Delivery Charges:', response.data);
    //   } else {
    //     enqueueSnackbar("Failed to fetch delivery charges", { variant: "error" });
    //   }
    // })
    // .catch(() => {
    //   enqueueSnackbar("Failed to fetch delivery charges", { variant: "error" });
    // });
 axios
    .get(`/api/order/delivery-charges?customerId=${state.customerId}`)
    .then((response) => {
      if (!response.data.error) {
        // Check if there is data available
        const fetchedData = response.data.data.deliverycharges[0];
        if (fetchedData) {
          const updatedFormData = {
            customerId: state.customerId || '',
            deliveryType: fetchedData.deliveryOption,
            orders: fetchedData.cost.orderValue.length
              ? fetchedData.cost.orderValue.map((order) => ({
                  minOrderValue: order.minOrderValue,
                  maxOrderValue: order.maxOrderValue,
                  charge: order.charge
                }))
              : [{ minOrderValue: '', maxOrderValue: '', charge: '' }],
            locations: fetchedData.cost.locationValue.length
              ? fetchedData.cost.locationValue.map((location) => ({
                  type: location.type || '',
                  charge: location.charge || ''
                }))
              : [{ type: '', charge: '' }],
            distances: fetchedData.cost.distanceValue.length
              ? fetchedData.cost.distanceValue.map((distance) => ({
                  minDistance: distance.minDistance || '',
                  maxDistance: distance.maxDistance || '',
                  charge: distance.charge || ''
                }))
              : [{ minDistance: '', maxDistance: '', charge: '' }]
          };
          setInitialFormData(updatedFormData);
        }
      } else {
        enqueueSnackbar('Failed to fetch delivery charges', { variant: 'error' });
      }
    })
    .catch(() => {
      enqueueSnackbar('Failed to fetch delivery charges', { variant: 'error' });
    });
    axios.get('/api/order/delivery-options'
  
  )
      .then(response => {
        if (!response.data.error) {
          
          console.log(response.data.data);
          setDeliveryOptions(response.data.data);
        } else {
          enqueueSnackbar("Failed to fetch delivery options", { variant: "error" });
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to fetch delivery options", { variant: "error" });
      });
  }, [enqueueSnackbar]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormData}
  //     initialValues={{
  //       customerId: state.customerId || '',
  //       deliveryType: '',
       
  //      orders: [ {minOrderValue: '',
  //               maxOrderValue: '',
  //               charge: ''}],
  //      locations: [{ type: '', charge: '' }],
  //       distances: [
  //   { minDistance: '', maxDistance: '', charge: '' },
    
  // ]
  //     }}
      
  
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            // Validate order values
      const invalidOrders = values.orders.some(
        (order) => parseFloat(order.minOrderValue) > parseFloat(order.maxOrderValue)
      );

      // Validate distance values
      const invalidDistances = values.distances.some(
        (distance) => parseFloat(distance.minDistance) > parseFloat(distance.maxDistance)
      );

      if (invalidOrders || invalidDistances) {
        setStatus({ success: false });
        setSubmitting(false);
        enqueueSnackbar("Validation failed: Ensure min values are less than max values.", { variant: "error" });
        return; // Stop submission if validation fails
      }

        console.log("Form Submitted with values:", values.locations);
        try {
      
          const cost = {};
          if (values.deliveryType === 'ORDER_VALUE') {
             cost.orderValue = values.orders.map((order) => ({
        minOrderValue: order.minOrderValue,
        maxOrderValue:order.maxOrderValue,
        charge: order.charge
      }));
    } 
     
        
          else if (values.deliveryType === 'LOCATION') {
                console.log("loc Values:", values.type, values.charge);
      cost.locationValue = values.locations.map((location) => ({
        location: location.type,
        charge: location.charge
      }));
    } 
          else if (values.deliveryType === 'DISTANCE') {
         
         cost.distanceValue = values.distances.map((distance) => ({
                  minDistance: distance.minDistance,
                maxDistance: distance.maxDistance,
                charge: distance.charge
          
               }));
    } 
        

          await axios.post('/api/admin/order/manage/delivery-charges', {
            customerId: values.customerId,
            deliveryOption: values.deliveryType,
            cost: cost,
          }, 
          // {
          //   headers: {
          //     'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhZG1pbkBnbWFpbC5jb20iLCJtb2JpbGUiOiI5ODg2MTY5MzQzIiwibmFtZSI6ImFkbWluIG5ldyBVc2VyIiwidXNlclR5cGUiOiJTVVBFUl9BRE1JTiIsInVzZXJJZCI6NDcsImxpY2Vuc2VFeHBpcnkiOiIyMDI3LTA5LTEzVDEyOjU5OjQyLjQ1NVoiLCJpYXQiOjE3MjYyMzIzODIsImV4cCI6MTcyNjMxODc4Mn0.Tc_BkAlDthDkfiroDyEV7sZqy59Luk5mumPLJoKmCV0'
          //   }
          // }
          );

          // Handle successful submission
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Delivery Charges Added", { variant: "success" });
          navigate("/app/management/customer/view");
        } catch (err) {
          // Handle error
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      }}
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, isSubmitting, values, touched
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card style={{ marginLeft: "2em", width: "600px" }}>
            <CardHeader title="Add Delivery Charges" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.customerId && errors.customerId)}
                    fullWidth
                    helperText={touched.customerId && errors.customerId}
                    label="Customer Id"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="delivery-type-label">Delivery Type</InputLabel>
                    <Select
                      labelId="delivery-type-label"
                      id="deliveryType"
                      name="deliveryType"
                      value={values.deliveryType}
                      onChange={(event) => {
                        handleChange(event);
                        // Show or hide fields based on delivery type
                        setShowOrderField(event.target.value === 'ORDER_VALUE');
                        setShowLocationField(event.target.value === 'LOCATION');
                        setShowDistanceField(event.target.value === 'DISTANCE');
                      }}
                      label="Delivery Type"
                      error={Boolean(touched.deliveryType && errors.deliveryType)}
                    >
                    {Object.entries(deliveryOptions).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                  
                    </Select>
                    {touched.deliveryType && errors.deliveryType && (
                      <FormHelperText error>{errors.deliveryType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                
             
{showOrderField && (
  <Grid item xs={12}>
    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px',position: 'relative', padding: '46px' }}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          color: 'black'
        }}
      >
        <CloseIcon sx={{ fontSize: 14 }} />
      </IconButton>
   <FieldArray name="orders">
        {({ push, remove }) => (
          <>
            {values.orders && values.orders.map((_, index) => (
              <Box
                key={index}
                sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', position: 'relative', marginBottom: '16px' }}
              >
                {/* {values.orders.length > 1 && (
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{ position: 'absolute', top: '16px', right: '16px', color: 'black' }}
                  >
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                )} */}
     
     
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body1">Min Order Value</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="Min Order Value"
                 name={`orders.${index}.minOrderValue`}
             
                onBlur={handleBlur}
                onChange={handleChange}
              
                value={values.orders[index]?.minOrderValue || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body1">Max Order Value</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="Max Order Value"
               name={`orders.${index}.maxOrderValue`}
                onBlur={handleBlur}
                onChange={handleChange}
            value={values.orders[index]?.maxOrderValue || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" spacing={9}>
            <Grid item>
              <Typography variant="body1">Charges</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="Charges"
                 name={`orders.${index}.charge`}
                onBlur={handleBlur}
                onChange={handleChange}
               value={values.orders[index]?.charge || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  </Box>
            ))}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => push({ minOrderValue: '', maxOrderValue: '', charge: '' })}
                >
                  Add More
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
    
    </Box>
  </Grid>
)}

{showLocationField && (
  <Grid item xs={12}>
    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '25px' }}>
      <IconButton
        onClick={handleLocationClose}
        sx={{
          position: 'relative',
          top: '0px',
          left: '500px',
          color: 'black'
        }}
      >
        <CloseIcon sx={{ fontSize: 20 }} />
      </IconButton> 

      <FieldArray name="locations">
        {({ push, remove }) => (
          <>
            {values.locations && values.locations.map((_, index) => (
              <Box
                key={index}
                sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', position: 'relative', marginBottom: '16px' }}
              >
                {/* {values.locations.length > 1 && (
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{ position: 'absolute', top: '16px', right: '16px', color: 'red' }}
                  >
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                )} */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography variant="body1">Location</Typography>
                      </Grid>
                      <Grid item xs>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id={`location-${index}-label`}>Location {index + 1}</InputLabel>
                          <Select
                            labelId={`location-${index}-label`}
                            id={`location${index}`}
                            name={`locations.${index}.type`}
                            value={values.locations[index]?.type || ''}
                            onChange={handleChange}
                            label={`Location ${index + 1}`}
                          >
                            <MenuItem value="SAME_CITY">SAME_CITY</MenuItem>
                            <MenuItem value="WITHIN_STATE">WITHIN_STATE</MenuItem>
                            <MenuItem value="ALL_INDIA">ALL_INDIA</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography variant="body1">Charges</Typography>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          fullWidth
                          label="Charges"
                          name={`locations.${index}.charge`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.locations[index]?.charge || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ))}
  <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
            
            <Button
              variant="outlined"
              onClick={() => push({ type: '', charge: '' })}
            >
              Add More
            </Button></Box>
          </>
        )}
      </FieldArray>
    </Box>
  </Grid>
)}

         
{showDistanceField && (
  <Grid item xs={12}>
    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '26px' }}>
      <IconButton
        onClick={handleDistanceClose}
        sx={{
          position: 'relative',
          top: '0px',
          left: '500px',
          color: 'black'
        }}
      >
        <CloseIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <FieldArray name="distances">
        {({ push, remove }) => (
          <>
            {values.distances && values.distances.map((_, index) => (
              <Box
                key={index}
                sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', position: 'relative', marginBottom: '16px' }}
              >
                {/* {values.distances.length > 1 && (
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{ position: 'absolute', top: '16px', right: '16px', color: 'red' }}
                  >
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                )} */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography variant="body1">Min Distance (Km)</Typography>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          fullWidth
                          label="Min Distance"
                          name={`distances.${index}.minDistance`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.distances[index]?.minDistance || ''}
                          variant="outlined"
                          type="number"
                          error={Boolean(
                            touched.distances?.[index]?.minDistance &&
                            errors.distances?.[index]?.minDistance
                          )}
                          helperText={
                            touched.distances?.[index]?.minDistance &&
                            errors.distances?.[index]?.minDistance
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography variant="body1">Max Distance (Km)</Typography>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          fullWidth
                          label="Max Distance"
                          name={`distances.${index}.maxDistance`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.distances[index]?.maxDistance || ''}
                          variant="outlined"
                          type="number"
                          error={Boolean(
                            touched.distances?.[index]?.maxDistance &&
                            errors.distances?.[index]?.maxDistance
                          )}
                          helperText={
                            touched.distances?.[index]?.maxDistance &&
                            errors.distances?.[index]?.maxDistance
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" spacing={12}>
                      <Grid item>
                        <Typography variant="body1">Charges</Typography>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          fullWidth
                          label="Charges"
                          name={`distances.${index}.charge`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.distances[index]?.charge || ''}
                          variant="outlined"
                          type="number"
                          error={Boolean(
                            touched.distances?.[index]?.charge &&
                            errors.distances?.[index]?.charge
                          )}
                          helperText={
                            touched.distances?.[index]?.charge &&
                            errors.distances?.[index]?.charge
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ))}
 <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
            <Button
              variant="outlined"
              onClick={() => push({ minDistance: '', maxDistance: '', charge: '' })}
            >
              Add More
            </Button>
               </Box>
          </>
        )}
      </FieldArray>
    </Box>
  </Grid>
)}

              </Grid>
            </CardContent>
         
            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

export default DeliveryCharges;
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE,
  SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE_SUCCESS,
  CHANGE_AUTH_CONTEXT, CHANGE_AUTH_CONTEXT_SUCCESS, CHANGE_AUTH_CONTEXT_FAILURE,
} from 'src/actions/accountActions';

import { USER_ROLES, BUSINESS_TYPES } from 'src/config';

const initialState = {
  user: null,
  activeRole: USER_ROLES.USER,
  businessType: BUSINESS_TYPES.GENERAL
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {

    case SIGNUP_SUCCESS: {
      return produce(state, (draft) =>{
          draft.user = null;
          draft.activeRole = null;
          draft.businessType = BUSINESS_TYPES.GENERAL;
      })
    }


    case SIGNIN: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
        draft.activeRole = null;
        draft.businessType = BUSINESS_TYPES.GENERAL;
      });
    }

    case SIGNIN_SUCCESS:
    case CHANGE_AUTH_CONTEXT_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.activeRole = user?.userType;
      });
    }

    case SIGNIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.activeRole = null;
        draft.businessType = BUSINESS_TYPES.GENERAL;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.activeRole = user?.userType;
      });
    }

    case UPDATE_PROFILE_SUCCESS: {
      const { userData } = action.payload;

      return produce(state, (draft) => {
        //draft.user = user;

        draft.user.name = userData.name;
        draft.user.mobile = userData.mobile;
        draft.user.dob = userData.dob;
        draft.user.state = userData.state;
        draft.user.country = userData.country;
        draft.activeRole = userData?.userType;       
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;

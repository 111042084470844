// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const REACT_APP_NAME = "admin";
export const APP_VERSION = '1.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;

export const IMAGE_BASE_URL = process.env.REACT_APP_PATH || "";

export const IMAGE_TYPE = {
    LOGO: "logo",
    SHORTLOGO: "shortlogo",
    BANNER1: "banner1",
    UPDATEBANNER1: "updatebanner1",
    BANNER2: "banner2",
    UPDATEBANNER2: "updatebanner2",
    BANNER3: "banner3",
    UPDATEBANNER3: "updatebanner3",
    BANNER4: "banner4",
    UPDATEBANNER4: "updatebanner4",
    BANNER5: "banner5",
    UPDATEBANNER5: "updatebanner5",
    BANNER6: "banner6",
    UPDATEBANNER6: "updatebanner6",
    PRODUCT_IMAGE: "product-image",
    PRODUCTIMAGE1: "productimage1",
    UPDATEPRODUCTIMAGE1: "updateproductimage1",
    PRODUCTIMAGE2: "productimage2",
    UPDATEPRODUCTIMAGE2: "updateproductimage2",
    PRODUCTIMAGE3: "productimage3",
    UPDATEPRODUCTIMAGE3: "updateproductimage3",
    PRODUCTIMAGE4: "productimage4",
    UPDATEPRODUCTIMAGE4: "updateproductimage4",
    PRODUCTIMAGE5: "productimage5",
    UPDATEPRODUCTIMAGE5: "updateproductimage5",
    PRODUCTIMAGE6: "productimage6",
    UPDATEPRODUCTIMAGE6: "updateproductimage6",
    NEWSIMAGE: "newsimage",
    GREETINGIMAGE: "greetingimage",
    CATEGORYIMAGE: "categoryimage",
    BASEIMAGE: "baseimage",
    TESTIMONIAL: "testimonialimage",
    SERVICE: "serviceimage"
  };
  
export const BUSINESS_TYPES = {
    GENERAL: 'GENERAL',
    SHOP: 'SHOP',
    BRIEFCASE: 'BRIEFCASE'
}

export const USER_ROLES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    USER: 'USER',
    SALES: 'SALES',
    DISTRIBUTOR: 'DISTRIBUTOR',
    SELLER: 'SELLER',
    ADMIN: 'ADMIN',
    SUPPORT: 'SUPPORT'   
}

export default {};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'src/utils/axios';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { USER_TYPE } from "src/utils/constants";
// import { RegisterCreate } from "src/actions/mgmtRegisterAction";
import { UPDATE_REGISTER_BUSINESS } from "src/constants";

const REGISTRATION_STATUS = {
  SUBMITTED: "SUBMITTED",
  IN_PROGRESS: "IN_PROGRESS",
  REJECTED: "REJECTED",
  HOLD: "HOLD",
  APPROVED: "APPROVED",
  COMPLETED: "COMPLETED",
};

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function RegisterRequestEditForm({ className,state,distributorCategoryList, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        BusinessId:state.businessId || '',
        status:state.status || '',
        businessName:state.businessName || '',
        email:state.email || '',
        mobile:state.mobile || '',
        businessType:state.businessType || '',
        requestedFrom:state.requestedFrom || '',
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log('Form submitted with values:', values); 
        try {
            const response = await axios.put(UPDATE_REGISTER_BUSINESS, {
      businessId: state.businessId,
      status: values.status,
      businessName: values.businessName,
      email: values.email,
      businessType: values.businessType,
    }); 

          console.log('API Response:', response);
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("User Updated", {
            variant: "success",
          });
          navigate("/app/management/customer/register");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("customerId or name Exits", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Update Register Request" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.businessId
 && errors.businessId

                    )}
                    fullWidth
                    helperText={
                      touched.businessId
 && errors.businessId

                    }
                    label="Business Id"
                    name="BusinessId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={state.businessId
}
                    variant="outlined"
                    disabled
                  />
                </Grid>
   <Grid item xs={6}>
                <FormControl fullWidth>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="status-select-label">Select Status</InputLabel>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          label="Select Status"
                          // onChange={handleChange}
                          name="status"
                          onBlur={handleBlur}
                          value={values.status}
                           onChange={(e) => setFieldValue("status", e.target.value)} // Updates Formik's value for 'status'
                        >
                           {Object.values(REGISTRATION_STATUS).map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
            </Grid>
              
                  <Grid item xs={12} sm={6}>
                      <TextField
                    error={Boolean(
                      touched.businessName && errors.businessName
                    )}
                    fullWidth
                    helperText={
                      touched.businessName && errors.businessName
                    }
                    label="businessName"
                    name="businessName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessName}
                    variant="outlined"
                    // disabled
                  />
                </Grid>
                <Grid item xs={6}>
                 <TextField
                    error={Boolean(
                      touched.email && errors.email
                    )}
                    fullWidth
                    helperText={
                      touched.email && errors.email
                    }
                    label="email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    // disabled
                  />
            </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.mobile && errors.mobile
                    )}
                    fullWidth
                    helperText={
                      touched.mobile && errors.mobile
                    }
                    label="mobile"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    variant="outlined"
                     disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.businessType && errors.businessType
                    )}
                    fullWidth
                    helperText={
                      touched.businessType && errors.businessType
                    }
                    label="businessType"
                    name="businessType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessType}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.requestedFrom && errors.requestedFrom
                    )}
                    fullWidth
                    helperText={
                      touched.requestedFrom && errors.requestedFrom
                    }
                    label="requestedFrom"
                    name="requestedFrom"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={state.requestedFrom}
                    variant="outlined"
                    disabled
                    
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )} */}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

RegisterRequestEditForm.propTypes = {
  className: PropTypes.string,
};

export default RegisterRequestEditForm;


import { Attribute, DeleteAttribute } from "src/interface/attribute";
import mgmtAttribute from "src/services/mgmtAttribute";

export const ATTIBUTE_SEARCH = '@attibute/search';
export const ATTIBUTE_SEARCH_SUCCESS = '@attibute/search-success';
export const ATTIBUTE_SEARCH_FAILURE = '@attibute/search-failure';

export const ATTRIBUTE_INFO = '@attribute/info';
export const ATTRIBUTE_CREATE = '@attribute/create';
export const ATTRIBUTE_CREATE_SUCCESS = '@attribute/create-success';
export const ATTRIBUTE_CREATE_FAILURE = '@attribute/create-failure';

export const ATTRIBUTE_DELETE = '@attribute/delete';
export const ATTRIBUTE_DELETE_SUCCESS = '@attribute/delete-success';
export const ATTRIBUTE_DELETE_FAILURE = '@attribute/delete-failure';

export function createAttribute(attributeInfo) {
  return async (dispatch) => {
    // console.log("createAttribute invalues: ", attributeInfo);
    let newAttribute = new Attribute(attributeInfo);
    // console.log("createAttribute new: ", newAttribute);
    
    try {
      dispatch({ type: ATTRIBUTE_CREATE });
      const attribute = await mgmtAttribute.createAttribute(newAttribute);
      dispatch({
        type: ATTRIBUTE_CREATE_SUCCESS,
        payload: {
          attribute
        }
      });
    } catch (error) {
      // console.log("createAttribute() ", error);
      dispatch({ type: ATTRIBUTE_CREATE_FAILURE });
      throw error;
    }
  };
}

export function deleteAttribute(customerId, attributeId) {
  return async (dispatch) => {
    // console.log("deleteAttribute invalues: ", customerId, attributeId);
    let attribute = new DeleteAttribute(customerId, attributeId);
    // console.log("deleteAttribute : ", attribute);
    
    try {
      dispatch({ type: ATTRIBUTE_DELETE });
      const attributeD = await mgmtAttribute.deleteAttribute(attribute);
      dispatch({
        type: ATTRIBUTE_DELETE_SUCCESS,
        payload: {
          attributeD
        }
      });
    } catch (error) {
      // console.log("deleteAttribute() ", error);
      dispatch({ type: ATTRIBUTE_DELETE_FAILURE });
      throw error;
    }
  };
}

export function fetchAttribute(customerId) {
  return async (dispatch) => {
    try {
      dispatch({ type: ATTRIBUTE_INFO });
      const attribute = await mgmtAttribute.fetchAttribute(customerId);
      // console.log("attribute Action", attribute);
      dispatch({
        type: ATTIBUTE_SEARCH_SUCCESS,
        payload: {
          attribute,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: ATTRIBUTE_CREATE_FAILURE });
      throw error;
    }
  };
}




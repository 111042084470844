import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    TextField,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import axios from 'src/utils/axios';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { fetchUsers5 } from 'src/actions/mgmtSelectValueAction';
import { fetchUserData } from 'src/actions/accountActions';
import Results from './Results';
import MperitoCard from 'src/components/MperitoCard';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: 100
    }
}));

function UserListView() {
    const classes = useStyles();

    const businessInfo = useSelector((state) => state?.app?.businessInfo);

    useEffect(() => {

    }, [businessInfo]);


    return (
        <Page
            className={classes.root}
            title="User List"
        >
            <Container maxWidth={false}>
                <Header />
                <br />
                <br />
                <MperitoCard title={`User Lists for Business ( ${businessInfo?.businessName} - ${businessInfo?.customerId} )`}>
                    <Results customerId={businessInfo?.customerId} />
                </MperitoCard>
            </Container>
        </Page>
    );
}

export default UserListView;

class Attribute {
  constructor(info) {
    this.customerId = info.customerId;
    this.attributeId = info.attributesId;
    this.name = info.attributeName;
    this.type = info.type;
    this.isFilterable = info.isFilterable;
    this.isSearchable = info.isSearchable;
  }
}

class DeleteAttribute {
  constructor(customerId, attributeId) {
    this.customerId = customerId;
    this.attributeId = attributeId;
  }
}

export default { Attribute };
export { Attribute, DeleteAttribute };


import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Results from './Result';
import { fetchAttribute } from 'src/actions/mgmtAttibuteAction';
import MperitoCard from 'src/components/MperitoCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function AttributeView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const businessInfo = useSelector((state) => state?.app?.businessInfo);
  const customerId = businessInfo?.customerId;

  const attributes = useSelector((state) => state?.attribute?.attribute);

  useEffect(() => {
    dispatch(fetchAttribute(customerId));
  }, [customerId]);

  useEffect(() => {

  }, [attributes]);
  
  return (
    <Page
      className={classes.root}
      title="Attribute List"
    >
      <Container maxWidth={false}>
        <Header />
        <br />
        <MperitoCard title={`List of Attributes ( ${customerId} )`} >
          <Results attributes={attributes} />
        </MperitoCard>
      </Container>
    </Page>
  );
}

export default AttributeView;

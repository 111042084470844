import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Field, FieldArray, Formik } from "formik";
import { useSnackbar } from "notistack";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
// import "./helper.css";
import {
  Box,
  Button,  
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createAttribute } from "src/actions/mgmtAttibuteAction";


const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function AttributeForm({ className, customerId, data, ...rest }) {
  const classes = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
  // console.log("ServiceCreateForm: addedBy", addedBy);

  return (

    <Formik
      enableReinitialize
      initialValues={{
        customerId: customerId,
        attributesId: data?.attributeId || undefined,
        attributeName: data?.name || "",
        type: data?.type || "string",
        isFilterable: data?.isFilterable ? data?.isFilterable : false,
        isSearchable: data?.isSearchable? data?.isSearchable : false,
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log("Submitting the request", addedBy, values);
        try {
          await dispatch(createAttribute(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Attribute Updated", {
            variant: "success",
          });
          navigate("/app/management/attribute/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("customerId or name Exits", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >

          <Grid container spacing={3} padding={2}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.attributeName && errors.attributeName
                )}
                fullWidth
                helperText={
                  touched.attributeName && errors.attributeName
                }
                label="Attribute Name"
                name="attributeName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.attributeName}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.type && errors.type
                )}
                fullWidth
                helperText={
                  touched.type && errors.type
                }
                label="Type"
                name="type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={values.isFilterable} onChange={handleChange} name="isFilterable" />}
                label="Is Filterable"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={values.isSearchable} onChange={handleChange} name="isSearchable" />}
                label="Is Searchable"
              />
            </Grid>

          </Grid>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2} padding={2}>
            <Button
              color="secondary" variant='contained'
              component={RouterLink}
              to="/app/management/attribute/view"
            >
              Cancel
            </Button>
            <Button style={{ marginLeft: '1em'}}
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

AttributeForm.propTypes = {
  className: PropTypes.string,
};

export default AttributeForm;



import {
  Truck as TruckIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Package as ServiceIcon,
  MapPin as LocationIcon,
  Briefcase as BusinessIcon,
} from "react-feather";

import BookmarksIcon from '@mui/icons-material/Bookmarks';
import PaymentIcon from '@mui/icons-material/Payment';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CategoryIcon from '@mui/icons-material/Category';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import { BUSINESS_TYPES, USER_ROLES } from 'src/config';

const UserNavConfig = [
  {
    subheader: "Dashboards",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/app/management/dashboard",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN,
        ],
      },
      {
        title: "Financial",
        icon: PieChartIcon,
        href: "/app/management/dashboard/financial",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN,
        ],
      },
      {
        title: "Financial Dashboard",
        icon: PieChartIcon,
        href: "/app/management/financial/dashboard",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN,
        ],
      },
      {
        title:"Business",
        icon: SupportAgentIcon,
        href: "/app/management/customer/view",
        role: [
          USER_ROLES.SUPER_ADMIN,
        ],
      },
       {
        title:"Register Request",
        icon: SupportAgentIcon,
        href: "/app/management/customer/register",
        role: [
          USER_ROLES.SUPER_ADMIN,USER_ROLES.ADMIN,
        ],
      },
      {
        title: "User",
        icon: AdminPanelSettingsIcon,
        href: "/app/management/user/view",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN,
        ],
      },
      {
        title: "Admin",
        icon: AdminPanelSettingsIcon,
        href: "/app/management/admin/view",
        role: [
          USER_ROLES.SUPER_ADMIN,
        ],
      },
    ],
  },
  {
    subheader: "Master",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "Payment Gateway Types",
        icon: PaymentIcon,
        href: "/app/management/payment/gateway/types",
        role: [
          USER_ROLES.SUPER_ADMIN,
        ],
      },
    ],
  },
  {
    subheader: "Catalog Management",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "Category",
        icon: CategoryIcon,
        href: "/app/management/category",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Category Old",
        icon: CategoryIcon,
        href: "/app/management/category/view",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN,
        ],
      },
      {
        title: "Product",
        icon: Inventory2Icon,
        href: "/app/management/product/view",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Attributes",
        icon: EditAttributesIcon,
        href: "/app/management/attribute/view",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
    ],
  },
  {
    subheader: "Order Management",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "Search Orders",
        icon: BookmarksIcon,
        href: "/app/management/order/search",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Search Invoices",
        icon: ReceiptIcon,
        href: "/app/management/invoice/search",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
    ],
  },
  {
    subheader: "Quotation",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "Quotation Request",
        icon: FormatQuoteIcon,
        href: "/app/management/quotation/request/list",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Generated Quotation",
        icon: FormatQuoteIcon,
        href: "/app/management/quotation/generated/view",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
    ],
  },
  {
    subheader: "Communication",
    businesstype: [BUSINESS_TYPES.GENERAL],
    items: [
      {
        title: "News",
        icon: NewspaperIcon,
        href: "/app/management/news/view",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Greeting",
        icon: CardGiftcardIcon,
        href: "/app/management/greetings/view",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Serivces",
        icon: BookmarksIcon,
        href: "/app/management/services",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Testimonials",
        icon: BookmarksIcon,
        href: "/app/management/testimonials",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
      {
        title: "Distributor Type",
        icon: BookmarksIcon,
        href: "/app/management/distributor/type",
        role: [
          USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUPPORT,
        ],
      },
    ],
  },
 
];

export default UserNavConfig;
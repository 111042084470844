import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PAYMENTGATEWAY_TYPE } from "src/utils/constants";
import { customerDetailsUpdate, fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CustomerDetailsEditForm({ className, service, banner, customerList, ...rest }) {
  const [myBooleanShowContacts, setMyBooleanShowContacts] = useState(service.showContacts)
  
  const [myBooleanshowProductPrice, setMyBooleanshowProductPrice] =useState(service.showProductPrice)
  const [myBooleanShowDistributor, setMyBooleanShowDistributor] = useState(service.showDistributor)
  const [myBooleanShowServiceCenters, setMyBooleanShowServiceCenters] = useState(service.showServiceCenters)
  const [myBooleanShowMaintenanceTroubleshooting, setMyBooleanShowMaintenanceTroubleshooting] = useState(service.showMaintenanceTroubleshooting)
  const [myBooleanIsEngineeringType, setMyBooleanIsEngineeringType] = useState(service.isEngineeringType)
  // const [myBooleanShowQuotationRequest, setMyBooleanShowQuotationRequest] = useState(service.showQuotationRequest)
  const [ondcIntegration, setOndcIntegration] = useState(service.ondcIntegration)
  const [showPos, setShowPos] = useState(service.showPos)

  // const isEngineeringType = (customerList) ? service.isEngineeringType : "false";
 const [myBooleanshowSubcategories, setMyBooleanshowSubcategories] = useState(service.showSubcategories)
  const [myBooleanPinchZoom, setMyBooleanPinchZoom] = useState(service.pinchZoom)
  const [myBooleanisTaxInclusivePrice, setMyBooleanisTaxInclusivePrice] = useState(service.isTaxInclusivePrice)
  const [myBooleanshowQuotationRequest, setMyBooleanshowQuotationRequest] = useState(service.showQuotationRequest)
  const [myBooleanshowBrand, setMyBooleanshowBrand] = useState(service.showBrand)
  const [myBooleanshowLargeImage, setMyBooleanshowLargeImage] = useState(service.showLargeImage)
  const [myBooleanshowSKU, setMyBooleanshowSKU] = useState(service.showSKU)
  const [myBooleanshowSqrCategory, setMyBooleanshowSqrCategory] = useState(service.showSqrCategory)
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (

    <Formik
      initialValues={{
        customerId: service.customerId || '',
        parentId: service.parentId || '',
        businessName: service.businessName || '',
        registeredEmail: service.registeredEmail || '',
        registeredAddress: service.registeredAddress || '',
        about: service.about || '',
        GSTN: service.GSTN || '',
        mobile: service.mobile || '',
        gst: service.gst || '',
        whatsApp: service.whatsApp || '',
        helpLine: service.helpLine || '',
        paymentGateway: service.paymentGateway || '',
        website: service.website || '',
        showContacts: service.showContacts || '',
        showProductPrice:service.showProductPrice || '',
        showSubcategories:service.showSubcategories || '',
        pinchZoom:service.pinchZoom || '',
 isTaxInclusivePrice:service.isTaxInclusivePrice || '',
 showQuotationRequest:service.showQuotationRequest || '',
 showBrand:service.showBrand || '',
 showLargeImage:service.showLargeImage || '',
 showSKU:service.showSKU || '',
 showSqrCategory:service.showSqrCategory  || '',
        showDistributor: service.showDistributor || '',
        showPos: service.showPos || '',
        ondcIntegration: service.ondcIntegration || '',
        showServiceCenters: service.showServiceCenters || '',
        showMaintenanceTroubleshooting: service.showMaintenanceTroubleshooting || '',
        isEngineeringType: service.isEngineeringType || '',
        businessLocation: service.businessLocation || '',
        // showQuotationRequest: service.showQuotationRequest || '',
        facebookUrl: service.socialLinks.facebookUrl || '',
        twitterUrl: service.socialLinks.twitterUrl || '',
        instagramUrl: service.socialLinks.instagramUrl || '',
        linkedInUrl: service.socialLinks.linkedInUrl || '',
        youtubeUrl: service.socialLinks.youtubeUrl || '',
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request",values);
        try {
          await dispatch(customerDetailsUpdate(values,
          myBooleanShowContacts,myBooleanShowDistributor,
          myBooleanShowServiceCenters
          ,myBooleanShowMaintenanceTroubleshooting
            ,myBooleanIsEngineeringType,
            ondcIntegration,showPos,myBooleanshowSubcategories,
            myBooleanPinchZoom,myBooleanisTaxInclusivePrice,
            myBooleanshowQuotationRequest,myBooleanshowBrand,
            myBooleanshowLargeImage,myBooleanshowSKU,
            myBooleanshowSqrCategory,myBooleanshowProductPrice));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Customer Details Updated ", {
            variant: "success",
          });
          dispatch(fetchCustomerList());
          navigate("/app/management/customer/view");
        } catch (err) {
          // setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("Customer Details Update Failed ", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            {/* <CardHeader title="Add Customer" />
            <Divider /> */}
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.customerId && errors.customerId
                    )}
                    fullWidth
                    helperText={
                      touched.customerId && errors.customerId
                    }
                    label="customerId"
                    name="customerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerId}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.parentId && errors.parentId
                    )}
                    fullWidth
                    helperText={
                      touched.parentId && errors.parentId
                    }
                    label="Parent Id"
                    name="parentId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.parentId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.businessName && errors.businessName
                    )}
                    fullWidth
                    helperText={
                      touched.businessName && errors.businessName
                    }
                    label="Business Name"
                    name="businessName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.registeredEmail && errors.registeredEmail
                    )}
                    fullWidth
                    helperText={
                      touched.registeredEmail && errors.registeredEmail
                    }
                    label="Registered Email"
                    name="registeredEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.registeredEmail}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.GSTN && errors.GSTN
                    )}
                    fullWidth
                    helperText={
                      touched.GSTN && errors.GSTN
                    }
                    label="GST Number"
                    name="GSTN"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.GSTN}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.registeredAddress && errors.registeredAddress
                    )}
                    fullWidth
                    helperText={
                      touched.registeredAddress && errors.registeredAddress
                    }
                    multiline={4}
                    label="Registered Address"
                    name="registeredAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.registeredAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline={8}
                    error={Boolean(
                      touched.about && errors.about
                    )}
                    fullWidth
                    helperText={
                      touched.about && errors.about
                    }
                    label="About"
                    name="about"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.about}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.mobile && errors.mobile
                    )}
                    fullWidth
                    helperText={
                      touched.mobile && errors.mobile
                    }
                    label="Mobile"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.whatsApp && errors.whatsApp
                    )}
                    fullWidth
                    helperText={
                      touched.whatsApp && errors.whatsApp
                    }
                    label="Whats App"
                    name="whatsApp"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.whatsApp}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.helpLine && errors.helpLine
                    )}
                    fullWidth
                    helperText={
                      touched.helpLine && errors.helpLine
                    }
                    label="Help Line"
                    name="helpLine"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.helpLine}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.website && errors.website
                    )}
                    fullWidth
                    helperText={
                      touched.website && errors.website
                    }
                    label="Website"
                    name="website"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.website}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                  <Autocomplete
                    value={values.paymentGateway}
                    onChange={(event, newValue) => {
                      setFieldValue("paymentGateway", newValue);
                    }}
                    disablePortal
                    id="paymentGateway"
                    options={PAYMENTGATEWAY_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.paymentGateway}
                    helperText={errors.paymentGateway}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Payment Gateway"
                          error={Boolean(touched.paymentGateway && errors.paymentGateway)}
                          helperText={touched.paymentGateway && errors.paymentGateway}
                        />
                      </>
                    )}
                  />
                </Grid> */}
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.businessLocation && errors.businessLocation
                    )}
                    fullWidth
                    helperText={
                      touched.businessLocation && errors.businessLocation
                    }
                    label="Business Location"
                    name="businessLocation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessLocation}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.gst && errors.gst
                    )}
                    fullWidth
                    helperText={
                      touched.gst && errors.gst
                    }
                    label="GST"
                    name="gst"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.gst}
                    variant="outlined"
                  />
                </Grid>
              
                    <Grid item xs={12} sm={3} style={{marginLeft:"10em"}}>
                      <label>Show Contacts</label>
                    </Grid>
                    <Grid item xs={12} sm={1} style={{marginLeft:"-12em",marginTop:"-0.2em"}}>
                      <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                        checked={myBooleanShowContacts}
                        onChange={() => {
                          setMyBooleanShowContacts(!myBooleanShowContacts)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"10em"}}>
                     <label>  Show Distributor </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-11.5em",marginTop:"-0.2em"}}>
                    <input type="checkbox"  style={{
      color: "#00e676",
      width: 30,
      height: 30
    }}
                      checked={myBooleanShowDistributor}
                      onChange={() => {
                        setMyBooleanShowDistributor(!myBooleanShowDistributor)
                      }}
                    />
                  </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"0.5em"}}>
                        <label> Show Maintenance Troubleshooting </label>
                    </Grid>
                  <Grid item xs={12} sm={1}    style={{marginLeft:"-3em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                 
                      checked={myBooleanShowMaintenanceTroubleshooting}
                      onChange={() => {
                        setMyBooleanShowMaintenanceTroubleshooting(!myBooleanShowMaintenanceTroubleshooting)
                      }}
                    />
                  </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"7.5em"}}>
                      <label>   Show Service Centers </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-9.5em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                      checked={myBooleanShowServiceCenters}
                      onChange={() => {
                        setMyBooleanShowServiceCenters(!myBooleanShowServiceCenters)
                      }}
                    />
                  </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"9.5em"}}>
                         <label> Is EngineeringType </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-11em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={myBooleanIsEngineeringType}
                      onChange={() => {
                        setMyBooleanIsEngineeringType(!myBooleanIsEngineeringType)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{marginLeft:"6.3em"}}>
                         <label> Ondc Integration </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-8.9em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={ondcIntegration}
                      onChange={() => {
                        setOndcIntegration(!ondcIntegration)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{marginLeft:"10.8em"}}>
                         <label> Show Pos </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-12.8em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={showPos}
                      onChange={() => {
                        setShowPos(!showPos)
                      }}
                    />
                  </Grid>

                    <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showProductPrice </label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowProductPrice}
                    onChange={() => {
                      setMyBooleanshowProductPrice(!myBooleanshowProductPrice)
                    }}
                  />
                </Grid>
  <Grid item xs={12} sm={3} style={{ marginLeft: "5.5em" }}>
                  <label>showSubcategories</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-8em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowSubcategories}
                    onChange={() => {
                      setMyBooleanshowSubcategories(!myBooleanshowSubcategories)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>pinchZoom</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-12em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanPinchZoom}
                    onChange={() => {
                      setMyBooleanPinchZoom(!myBooleanPinchZoom)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>isTaxInclusivePrice</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanisTaxInclusivePrice}
                    onChange={() => {
                      setMyBooleanisTaxInclusivePrice(!myBooleanisTaxInclusivePrice)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "5.5em" }}>
                  <label>showQuotationRequest</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-8em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowQuotationRequest}
                    onChange={() => {
                      setMyBooleanshowQuotationRequest(!myBooleanshowQuotationRequest)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showBrand</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-12em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowBrand}
                    onChange={() => {
                      setMyBooleanshowBrand(!myBooleanshowBrand)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showLargeImage</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowLargeImage}
                    onChange={() => {
                      setMyBooleanshowLargeImage(!myBooleanshowLargeImage)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showSKU</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-12.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowSKU}
                    onChange={() => {
                      setMyBooleanshowSKU(!myBooleanshowSKU)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "9em" }}>
                  <label>showSqrCategory</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowSqrCategory}
                    onChange={() => {
                      setMyBooleanshowSqrCategory(!myBooleanshowSqrCategory)
                    }}
                  />
                </Grid>
               
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.linkedInUrl && errors.linkedInUrl
                    )}
                    fullWidth
                    helperText={
                      touched.linkedInUrl && errors.linkedInUrl
                    }
                    label="LinkedIn Url"
                    name="linkedInUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.linkedInUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.instagramUrl && errors.instagramUrl
                    )}
                    fullWidth
                    helperText={
                      touched.instagramUrl && errors.instagramUrl
                    }
                    label="Instagram Url"
                    name="instagramUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.instagramUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.youtubeUrl && errors.youtubeUrl
                    )}
                    fullWidth
                    helperText={
                      touched.youtubeUrl && errors.youtubeUrl
                    }
                    label="Youtube Url"
                    name="youtubeUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.youtubeUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.twitterUrl && errors.twitterUrl
                    )}
                    fullWidth
                    helperText={
                      touched.twitterUrl && errors.twitterUrl
                    }
                    label="Twitter Url"
                    name="twitterUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.twitterUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.facebookUrl && errors.facebookUrl
                    )}
                    fullWidth
                    helperText={
                      touched.facebookUrl && errors.facebookUrl}
                    label="Facebook Url"
                    name="facebookUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.facebookUrl}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CustomerDetailsEditForm.propTypes = {
  className: PropTypes.string,

};

export default CustomerDetailsEditForm;



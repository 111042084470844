
export const SEARCH_ORDERS_URL = "/api/admin/order/by/customerId";
export const SEARCH_INVOICES_URL = "/api/admin/invoice/by/customerId";

export const UPDATE_ORDER_STATUS_URL = "/api/admin/order/update/status"

export const BUSINESS_DETAILS_URL = "/api/customer/details";

export const CHANGE_AUTH_CONTEXT_URL = "/api/auth/admin/change/login";

export const FETCH_NEWS_URL = "/api/customer/info/whatsnews";
export const FETCH_GREETINGS_URL = "/api/customer/info/greetings";

export const FETCH_TESTIMONIALS_URL = "/api/customer/testimony";
export const CREATE_TESTIMONIAL_URL = "/api/admin/customer/testimony/add-edit";
export const TESTIMONIAL_DELETE_URL = "/api/admin/customer/testimony/remove";

export const FETCH_SERVICES_URL = "/api/customer/service";
export const CREATE_SERVICE_URL = "/api/admin/customer/service";

export const DASHBOARD_FINANCIAL_ORDER_SALES_YEARLY_URL = "/api/dashboard/order/financial/year/order-sales";
export const DASHBOARD_FINANCIAL_ORDER_SALES_TODAY_URL = "/api/dashboard/order/today/order-sales"

export const DASHBOARD_ORDERS_TREND_WEEK_URL = "/api/dashboard/order/week/day-wise/order-sales";
export const DASHBOARD_ORDERS_TREND_MONTH_URL = "/api/dashboard/order/month/day-wise/order-sales";

export const CREATE_ATTRIBUTE = "/api/admin/catalog/products/attribute";
export const DELETE_ATTRIBUTE = "/api/admin/catalog/products/delete/attribute";

// /api/admin/customer/testimony/add-edit [POST]
// /api/admin/customer/testimony/remove?testimonyId=7 [DELETE]
// /api/admin/customer/service [POST]
// /api/admin/customer/service [DELETE]




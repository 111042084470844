import authService from "src/services/authService";
import { NewUserReqInfo } from "src/interface/authIf";
import mgmtUser from "src/services/mgmtUser";
import { fetchBusinesses } from "./businessActions";
export const SIGNUP = "@auth/signup";
export const SIGNUP_SUCCESS = "@auth/signup-success";
export const SIGNUP_FAILURE = "@auth/signup-failure";
export const SIGNIN = "@auth/signin";
export const SIGNIN_SUCCESS = "@auth/signin-success";
export const SIGNIN_FAILURE = "@auth/signin-failure";
export const SIGNOUT = "@auth/signout";
export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const REGISTER = "@account/register";
export const UPDATE_PROFILE = "@account/update-profile";
export const UPDATE_PROFILE_SUCCESS = "@account/update-profile-success";
export const UPDATE_PROFILE_FAILED = "@account/update-profile-failed";
export const CHANGE_PASSWORD = "@account/change-password";
export const CHANGE_PASSWORD_SUCCESS = "@account/change-password-success";
export const CHANGE_PASSWORD_FAILED = "@account/change-password-failure";
export const FETCHUSER_DATA = "@userdata/info";
export const FETCHUSER_DATA_SUCCESS = "@userdata/userdata-success";
export const FETCHUSER_DATA_FAILED = "@userdata/userdata-failure";
export const FETCHUSERTARGET_DATA = "@usertargetdata/info";
export const FETCHUSERTARGET_DATA_SUCCESS = "@usertargetdata/usertargetdata-success";
export const FETCHUSERTARGET_DATA_FAILED = "@usertargetdata/usertargetdata-failure";

export const CHANGE_AUTH_CONTEXT = "@auth/change-auth-context";
export const CHANGE_AUTH_CONTEXT_SUCCESS = "@auth/change-auth-context-success";
export const CHANGE_AUTH_CONTEXT_FAILURE = "@auth/change-auth-context-failure";

export function signup(userInfo) {
  return async (dispatch) => {
    const newUserInfo = new NewUserReqInfo(userInfo);
    console.log("Account Actions: Signup:", userInfo, newUserInfo);
    try {
      dispatch({ type: SIGNUP });
      const email = await authService.signup(newUserInfo);
      console.log("Account Actions: Signup Resp:", email);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: {
          email
        },
      });
    } catch (error) {
      console.log("Account Actions: Error ", error);
      dispatch({ type: SIGNUP_FAILURE });
      throw error;
    }
  };
}

export function signin(email, password, customerId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SIGNIN });
      console.log("Account Actions: Signin:", email, password, customerId);
      const user = await authService.signinWithEmailAndPassword(email, password, customerId);
      dispatch({
        type: SIGNIN_SUCCESS,
        payload: {
          user,
        },
      });

      // Fetch all the initialization data
      await dispatch(fetchBusinesses());


    } catch (error) {
      console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: SIGNIN_FAILURE });
      throw error;
    }
  };
}

export function changeAuthContext(email, customerId) {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_AUTH_CONTEXT });
      // console.log("Change Auth Context:", email, customerId);
      const user = await authService.changeAuthContext(email, customerId);
      dispatch({
        type: CHANGE_AUTH_CONTEXT_SUCCESS,
        payload: {
          user,
        },
      });

      // Fetch all the initialization data
      await dispatch(fetchBusinesses());


    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: CHANGE_AUTH_CONTEXT_FAILURE });
      throw error;
    }
  };
}

export function signout() {
  return async (dispatch) => {
    authService.signout();
    dispatch({
      type: SIGNOUT,
    });
  };
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
}

export function fetchUserData(customerId) {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHUSER_DATA });
      const userData = await mgmtUser.fetchUserData(customerId);
      console.log("userData", userData);
      dispatch({
        type: FETCHUSER_DATA_SUCCESS,
        payload: {
          userData,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCHUSER_DATA_FAILED });
      throw error;
    }
  };
}

export function fetchUserTargetData(id,userId) {
  console.log("id",id,userId);

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHUSERTARGET_DATA });
      const userTargetData = await mgmtUser.fetchUserTargetData(id,userId);
      console.log("userTargetData", userTargetData);
      dispatch({
        type: FETCHUSERTARGET_DATA_SUCCESS,
        payload: {
          userTargetData,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCHUSERTARGET_DATA_FAILED });
      throw error;
    }
  };
}

import axios from 'src/utils/axios';

class MgmtCustomerForm {

  createCustomer = (newCustomer, image, files, image2,myBooleanShowContacts,myBooleanShowDistributor,myBooleanShowServiceCenters,myBooleanShowMaintenanceTroubleshooting
    ,myBooleanIsEngineeringType,ondcIntegration, 
        showPos,myBooleanshowProductPrice,myBooleanshowSubcategories,myBooleanPinchZoom,
 myBooleanisTaxInclusivePrice,myBooleanshowQuotationRequest,myBooleanshowBrand,myBooleanshowLargeImage,
 myBooleanshowSKU,myBooleanshowSqrCategory
 ) => new Promise((resolve, reject) => {
    const formData = new FormData()
    // formData.append('mobile', newCustomer.mobile);
    formData.append('businessName', newCustomer.businessName);
    formData.append('mobile', newCustomer.mobile);
    formData.append('whatsApp', newCustomer.whatsApp);
    formData.append('helpLine', newCustomer.helpLine);
    formData.append('registeredEmail', newCustomer.registeredEmail);
    formData.append('registeredAddress', newCustomer.registeredAddress);
    // eslint-disable-next-line no-template-curly-in-string
  
    if (newCustomer.GSTN) {
      formData.append('GSTN', newCustomer.GSTN);
    }
    formData.append('about', newCustomer.about);
    if (newCustomer.paymentGateway) {
      formData.append('paymentGateway', newCustomer.paymentGateway);
    }
    if (newCustomer.gatewayType) {
      formData.append('gatewayType', newCustomer.gatewayType);
    }
    // if (newCustomer.showContacts) {
      formData.append('showContacts',myBooleanShowContacts);
    
      // }
// if (newCustomer.showProductPrice) {
      formData.append('showProductPrice',myBooleanshowProductPrice);
    // }
    // if (newCustomer.showSubcategories) {
      formData.append('showSubcategories',myBooleanshowSubcategories);
    // }
    // if (newCustomer.pinchZoom) {
      formData.append('pinchZoom',myBooleanPinchZoom);
      formData.append('showPos',showPos);
      formData.append('ondcIntegration',ondcIntegration);
      // showPos
    // }
    // if (newCustomer.isTaxInclusivePrice) {
      formData.append('isTaxInclusivePrice',myBooleanisTaxInclusivePrice);
    // }
    //  if (newCustomer.showQuotationRequest) {
      formData.append('showQuotationRequest',myBooleanshowQuotationRequest);
    // }
    //  if (newCustomer.showBrand) {
      formData.append('showBrand',myBooleanshowBrand);
    // }
      // if (newCustomer.showLargeImage) {
      formData.append('showLargeImage',myBooleanshowLargeImage);
    // }  if (newCustomer.showSKU) {
      formData.append('showSKU',myBooleanshowSKU);
    // }  if (newCustomer.showSqrCategory) {
      formData.append('showSqrCategory',myBooleanshowSqrCategory);
    // }
//add

    // if (newCustomer.showDistributor) {
      formData.append('showDistributor',myBooleanShowDistributor);
    // }

    // if (newCustomer.showServiceCenters) {
      formData.append('showServiceCenters',myBooleanShowServiceCenters);
    // }

    // if (newCustomer.showMaintenanceTroubleshooting) {
      formData.append('showMaintenanceTroubleshooting', myBooleanShowMaintenanceTroubleshooting);
    // }
    // if (newCustomer.isEngineeringType) {
      formData.append('isEngineeringType',myBooleanIsEngineeringType);
    // }
    if (newCustomer.website) {
      formData.append('website', newCustomer.website);
    }
    if (newCustomer.facebookUrl) {
      formData.append('facebookUrl', newCustomer.facebookUrl);
    }
    if (newCustomer.twitterUrl) {
      formData.append('twitterUrl', newCustomer.twitterUrl);
    }
    if (newCustomer.instagramUrl) {
      formData.append('instagramUrl', newCustomer.instagramUrl);
    }
    if (newCustomer.linkedInUrl) {
      formData.append('linkedInUrl', newCustomer.linkedInUrl);
    }
    if (newCustomer.youtubeUrl) {
      formData.append('youtubeUrl', newCustomer.youtubeUrl);
    }
    if (newCustomer.parentId) {
      formData.append('parentId', newCustomer.parentId);
    }
    formData.append('brandName', newCustomer.brandName);
    formData.append('appName', newCustomer.appName);
    formData.append('appDescription', newCustomer.appDescription);
    formData.append('bannerContactAddress', newCustomer.bannerContactAddress);
    formData.append('primaryColor', newCustomer.primaryColor);
    formData.append('secondaryColor', newCustomer.secondaryColor);
    formData.append('scaffoldColor', newCustomer.scaffoldColor);
    formData.append('backgroundColor', newCustomer.backgroundColor);
    formData.append('fontFamily', newCustomer.fontFamily);
    formData.append('fontColor', newCustomer.fontColor);
    formData.append('buttonText', newCustomer.buttonText);
    if (newCustomer.gatewayPayKeyId) {
      formData.append('gatewayPayKeyId', newCustomer.gatewayPayKeyId);
    }

    if (newCustomer.gatewayPayKeySecret) {
      formData.append('gatewayPayKeySecret', newCustomer.gatewayPayKeySecret);
    }

    formData.append('aboutUs', newCustomer.aboutUs);
    if (newCustomer.termsOfUse) {
      formData.append('termsOfUse', newCustomer.termsOfUse);
    }

    if (newCustomer.privacyPolicy) {
      formData.append('privacyPolicy', newCustomer.privacyPolicy);
    }

    if (newCustomer.businessLocation) {
      formData.append('businessLocation', newCustomer.businessLocation);
    }

    if (newCustomer.showQuotationRequest) {
      formData.append('showQuotationRequest',myBooleanshowQuotationRequest);
    }
    formData.append('logoFile', image);
    if (files) {
   
      for (let i = 0; i < files.length; i++) {
        formData.append(`bannerFiles`, files[i]);
      }
    }
    
    if (image2) {
      formData.append('certificates', image2);
    }
// Log FormData contents
    const formDataEntries = [];
    formData.forEach((value, key) => {
        formDataEntries.push({ key, value });
    });
    console.log('FormData Entries:', formDataEntries);
  console.log("new--------------");
    console.log(formData);
    console.log("new--------------");
    axios.post('/api/admin/customer/addCustomer', formData)
      .then((response) => {
        // console.log("MgmtCustomer(Response):" + JSON.stringify(response));
         console.log('API Response:', response.data);
        if (response.data) {
          let services = response.data.data.customerResponse;
          resolve(services);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  
  customerBannerAndLogoUpload = (newCustomerLogo, image, files, shortLogoImage,file,customerId) => new Promise((resolve, reject) => {
 
    const formData = new FormData()
    formData.append('customerId',customerId);
    formData.append('insertOrOverwrite', "1");
    formData.append('logoFile', file);
    
    if (shortLogoImage) {
      formData.append('shortLogo', shortLogoImage);
    }
  
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        formData.append(`bannerFiles`, files[i]);
      }
    
    }
    axios.post('/api/admin/customer/upload/images', formData)
      .then((response) => {
        // console.log("MgmtUpload(Response)" + JSON.stringify(response));
        if (response.data) {
          let logoandbannerupload = response.data;
          resolve(logoandbannerupload);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  customerBrandDetailsUpdate = (editAdminPassword) => new Promise((resolve, reject) => {

    const payload =
    {
      "customerId": editAdminPassword.customerId,
      "brandId": editAdminPassword.brandId,
      "brandDetails": {
        "theme": {
          "primaryColor": editAdminPassword.primaryColor,
          "secondaryColor": editAdminPassword.secondaryColor,
          "scaffoldColor": editAdminPassword.scaffoldColor,
          "backgroundColor": editAdminPassword.backgroundColor,
          "fontFamily": editAdminPassword.fontFamily,
          "fontColor": editAdminPassword.fontColor
        },
        "brandName": editAdminPassword.brandName,
        "appName": editAdminPassword.appName,
        "appDescription": editAdminPassword.appDescription
      }

    };

    axios.post('/api/admin/customer/manage/brand-details', payload)
      .then((response) => {
        // console.log("MgmtAdminChangePassword(Response) service:", response);
        // console.log("MgmtCustomerBrandDetailsUpdate(Response):" + JSON.stringify(response));
        if (response?.data?.data) {
          let brandDetails = response.data.data;
          resolve(brandDetails);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("MgmtCustomerBrandDetailsUpdate (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })


  customerPaymentDetailsUpdate = (payload) => 
    new Promise((resolve, reject) => {

    axios.post('/api/payment/manage/credentials', payload)
      .then((response) => {
        console.log("MgmtAdminChangePassword(Response) service:", response);
        // console.log("MgmtCustomerBrandDetailsUpdate(Response):" + JSON.stringify(response));
        if (response?.data?.data) {
          let brandDetails = response.data.data;
          resolve(brandDetails);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("MgmtCustomerBrandDetailsUpdate (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  customerJsonDownload = (title,value) => new Promise((resolve, reject) => {
  
    const payload =
    {
      "customerId":[title],
      "exportType":value
    }
        axios.post('/api/admin/customer/export/customer-list', payload)
          .then((response) => {
            // console.log("MgmtAdminChangePassword(Response) service:", response);
            // console.log("MgmtCustomerBrandDetailsUpdate(Response):" + JSON.stringify(response));
            if (response?.data?.data) {
              let brandDetails = response.data.data;
              resolve(brandDetails);
            } else {
              reject(response.data.error);
            }
          })
          .catch((error) => {
            // console.log("MgmtCustomerBrandDetailsUpdate (Response Error):" + JSON.stringify(error));
            reject(error);
          });
      })

  customerPrivacyPolicyUpdate = (privacyPolicy) => new Promise((resolve, reject) => {

    const payload =
    {
      "customerId": privacyPolicy.customerId,
      "policyId": privacyPolicy.policyId,
      "privacyPolicy": {
        "termsOfUse": privacyPolicy.termsOfUse,
        "privacyPolicy": privacyPolicy.privacyPolicy
      }
    }

    axios.post('/api/admin/customer/manage/privacy-policy', payload)
      .then((response) => {
        // console.log("MgmtAdminChangePassword(Response) service:", response);
        // console.log("MgmtPrivacyPolicyUpdate(Response):" + JSON.stringify(response));
        if (response?.data?.data) {
          let privacyPolicy = response.data.data;
          resolve(privacyPolicy);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("MgmtPrivacyPlolicyUpdate (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  customerDetailsUpdate = (customerDetails, myBooleanShowContacts,myBooleanShowDistributor,
          myBooleanShowServiceCenters
          ,myBooleanShowMaintenanceTroubleshooting
            ,myBooleanIsEngineeringType,
            ondcIntegration,showPos,myBooleanshowSubcategories,
            myBooleanPinchZoom,myBooleanisTaxInclusivePrice,
            myBooleanshowQuotationRequest,myBooleanshowBrand,
            myBooleanshowLargeImage,myBooleanshowSKU,
            myBooleanshowSqrCategory,myBooleanshowProductPrice) => new Promise((resolve, reject) => {

    const payload =
    {
      "customerId": customerDetails.customerId,
      "customerData": {
        "paymentGateway": customerDetails.paymentGateway,
        "showContacts": myBooleanShowContacts,
        "showDistributor": myBooleanShowDistributor,
        "showServiceCenters": myBooleanShowServiceCenters,
        "ondcIntegration": ondcIntegration,
        "showPos": showPos,
        "showProductPrice":myBooleanshowProductPrice,
        "showSubcategories":myBooleanshowSubcategories,
        "pinchZoom":myBooleanPinchZoom,
        "isTaxInclusivePrice":myBooleanisTaxInclusivePrice,
        "showQuotationRequest":myBooleanshowQuotationRequest,
        "showBrand":myBooleanshowBrand,
        "showLargeImage":myBooleanshowLargeImage,
        "showSKU":myBooleanshowSKU,
        "showSqrCategory":myBooleanshowSqrCategory,
        "showMaintenanceTroubleshooting": myBooleanShowMaintenanceTroubleshooting,
        "isEngineeringType": myBooleanIsEngineeringType,
        "website": customerDetails.website,
        "socialLinks": {
          "facebookUrl": customerDetails.facebookUrl,
          "twitterUrl": customerDetails.twitterUrl,
          "instagramUrl": customerDetails.instagramUrl,
          "linkedInUrl": customerDetails.linkedInUrl,
          "youtubeUrl": customerDetails.youtubeUrl
        },
        "businessName": customerDetails.businessName,
        "mobile": customerDetails.mobile,
        "whatsApp": customerDetails.whatsApp,
        "helpLine": customerDetails.helpLine,
        "registeredEmail": customerDetails.registeredEmail,
        "registeredAddress": customerDetails.registeredAddress,
        "businessLocation": customerDetails.businessLocation,
        // "showQuotationRequest":myBooleanShowQuotationRequest,
        "GSTN": customerDetails.GSTN,
        "gst": customerDetails.gst,
        "about": customerDetails.about,
        "parentId": customerDetails.parentId
      }
    }
     console.log("Payload for customerDetailsUpdate:", JSON.stringify(payload, null, 2));
    axios.post('/api/admin/customer/manage/customer-details', payload)
      .then((response) => {
        // console.log("MgmtAdminChangePassword(Response) service:", response);
        // console.log("MgmtCustomerDetailsUpdate(Response):" + JSON.stringify(response));
        if (response.data) {
          let cusmerDetails = response.data;
          resolve(cusmerDetails);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("MgmtCustomerDetailsUpdate (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchCustomerList = () => new Promise((resolve, reject) => {

    const admin = `/api/admin/customer/all`
    axios
      .get(admin)
      .then((response) => {
        // console.log("MgmtAdmin(fetchAdmin->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const sadmin = (response?.data?.data) ? response.data.data.customers : nodata;
          resolve(sadmin);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchAdminCustomerList = (cid) => new Promise((resolve, reject) => {
    const sadmin = `/api/customer/details?customerId=${cid}`
    axios
      .get(sadmin)
      .then((response) => {
        // console.log("MgmtAdmin(fetchAdmin->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const admin = (response?.data?.data) ? [response.data.data] : nodata;
          resolve(admin);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchPaymentInfoList = (cid) => new Promise((resolve, reject) => {
    const url = `/api/payment/credentials?customerId=${cid}`
    axios
      .get(url)
      .then((response) => {
        // console.log("MgmtAdmin(fetchAdmin->Response): 1", response);
        if (response?.data) {
          const nodata = []
          const paymentInfo = (response?.data?.data) ? response.data.data : nodata;
          resolve(paymentInfo);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  addDeliveryCharges = (values) => new Promise((resolve, reject) => {
    console.log("MgmtCustomerForm.addDeliveryCharges() :: values: ", values);

    const payload = {
      "customerId": values.customerId,
      "distance": values.distance,
      "cost": values.cost,
    };
  
    axios.post('/api/admin/order/manage/delivery-charges', payload)
    .then((response) => {
      // console.log("MgmtAdminChangePassword(Response) service:", response);
      console.log("addDeliveryCharges(Response):" + JSON.stringify(response));
      if(response.data){
        let deliveryCharges = response.data;
        resolve(deliveryCharges);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      console.log("addDeliveryCharges (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchDeliveryChargeList = (cid) => new Promise((resolve, reject) => {
    console.log("MgmtCustomerForm.fetchDeliveryChargeList() :: cid: ", cid);

    let axiosUrl = `/api/admin/order/get/delivery-charges`;
    if(cid != undefined && cid != null && cid != ""&& cid != 0) {
      axiosUrl = `${axiosUrl}?customerId=${cid}`;
    }
    const axiosConfig = {
      // headers: {
      //   'customerId': 807
      // }
    };
    axios
      .get(axiosUrl, axiosConfig)
      .then((response) => {
        if (response?.data) {
          const result = (response?.data?.data) ? [response.data.data] : [];
          console.log("MgmtCustomerForm.fetchDeliveryChargeList() :: result: ", result);
          resolve(result);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })
  
}

const mgmtCustomerForm = new MgmtCustomerForm();

export default mgmtCustomerForm;

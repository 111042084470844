import mgmtRegister from '../services/mgmtRegisterRequestForm'; // Import service for API call

export const FETCH_INQUIRY_REQUEST = 'FETCH_INQUIRY_REQUEST';
export const FETCH_INQUIRY_SUCCESS = 'FETCH_INQUIRY_SUCCESS';
export const FETCH_INQUIRY_FAILURE = 'FETCH_INQUIRY_FAILURE';

// Action creator to fetch inquiry data
export const fetchInquiryData = () => async (dispatch) => {
  dispatch({ type: FETCH_INQUIRY_REQUEST });

  try {
    const registerUser = await mgmtRegister.fetchRegisteredBusinessData(); // Fetching data from service
 console.log("API ResponseSQ:", registerUser);
   // Check if the response has the expected data structure
    if (registerUser) {
    //  console.log("Registered Businesses:", response.payload);
      
      dispatch({
        type: FETCH_INQUIRY_SUCCESS,
        payload: registerUser, // Accessing registeredBusiness directly
      });
    } else {
      // Handle the case where the structure is unexpected
      dispatch({
        type: FETCH_INQUIRY_FAILURE,
        payload: 'No registered business data ',
      });
    }
  }
  
  catch (error) {
    dispatch({
      type: FETCH_INQUIRY_FAILURE,
      payload: error.message || 'Something went wrong', // Handling errors
    });
  }
};
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createCustomer, fetchCustomerList } from "src/actions/mgmtCustomerCreateAction";
import { FONTFAMILY_TYPE, PAYMENTGATEWAYTYPE_TYPE, PAYMENTGATEWAY_TYPE} from "src/utils/constants";
import ConfirmBox from "src/views/product/ProductEditView/ConfirmBox";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function CustomerCreateForm({ className, cid, onClick, customerIdList, userType, customerId, ...rest }) {
const location = useLocation();
   const state = location.state || {};
 
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const addedBy = account.user.email;
 const [myBooleanShowContacts, setMyBooleanShowContacts] = useState(false)
  
  const [myBooleanshowProductPrice, setMyBooleanshowProductPrice] =useState(false)
  const [myBooleanShowDistributor, setMyBooleanShowDistributor] = useState(false)
  const [myBooleanShowServiceCenters, setMyBooleanShowServiceCenters] = useState(false)
  const [myBooleanShowMaintenanceTroubleshooting, setMyBooleanShowMaintenanceTroubleshooting] = useState(false)
  const [myBooleanIsEngineeringType, setMyBooleanIsEngineeringType] = useState(false)
  // const [myBooleanShowQuotationRequest, setMyBooleanShowQuotationRequest] = useState(false)
  const [ondcIntegration, setOndcIntegration] = useState(false)
  const [showPos, setShowPos] = useState(false)

  // const isEngineeringType = (customerList) ? service.isEngineeringType : "false";
 const [myBooleanshowSubcategories, setMyBooleanshowSubcategories] = useState(false)
  const [myBooleanPinchZoom, setMyBooleanPinchZoom] = useState(false)
  const [myBooleanisTaxInclusivePrice, setMyBooleanisTaxInclusivePrice] = useState(false)
  const [myBooleanshowQuotationRequest, setMyBooleanshowQuotationRequest] = useState(false)
  const [myBooleanshowBrand, setMyBooleanshowBrand] = useState(false)
  const [myBooleanshowLargeImage, setMyBooleanshowLargeImage] = useState(false)
  const [myBooleanshowSKU, setMyBooleanshowSKU] = useState(false)
  const [myBooleanshowSqrCategory, setMyBooleanshowSqrCategory] = useState(false)
 
 
  const [image, setImage] = useState('')
  function handleImage(e) {
    setImage(e.target.files[0])
  }

  const [image2, setImage2] = useState('')
  function handleImage2(e) {
    setImage2(e.target.files[0])
  }

  const ref = useRef();
  const reset = () => {
    ref.current.value = "";
  };

  const [files, setFiles] = useState([]);
  const bannerLenth = files.length

  const imageLenth = (bannerLenth === 5) ? 1 :
    (bannerLenth === 4) ? 2 :
      (bannerLenth === 3) ? 3 :
        (bannerLenth === 2) ? 4 :
          (bannerLenth === 1) ? 5 : 6

  const MAX_LENGTH = imageLenth;

  const [open, setOpen] = useState(false);
  const [iLength, setImageLength] = useState({});

  function openDelete(maxlength) {
    setOpen(true);
    setImageLength(maxlength);
  }

  function uploadFileHandler(event) {
    if (Array.from(event.target.files).length > MAX_LENGTH) {
      event.preventDefault([]);
      openDelete(MAX_LENGTH)
      reset();
      return
    }
    setFiles(event.target.files);
  };

  return (

    <Formik
      initialValues={{
        // mobile: "",
         mobile: state.mobile || '',
        // businessName: "",
           businessName:state.businessName || '',
        whatsApp: "",
        helpLine: "",
        gatewayType: "",
        // registeredEmail: "",
           registeredEmail: state.email,
        registeredAddress: "",
        GSTN: "",
        about: "",
        paymentGateway: "",
        showContacts: '',
          showProductPrice: '',
        showSubcategories: '',
          showPinchZoom: "",
 isTaxInclusivePrice: '',
 showQuotationRequest: '',
 showBrand:'',
 showLargeImage:'',
 showSKU:'',
 showSqrCategory:'',
      
        showDistributor: "",
        showServiceCenters: "",
        showMaintenanceTroubleshooting: "",

        // isEngineeringType: "",
        businessLocation: "",
        // showQuotationRequest: "",
        website: "",

        facebookUrl: "",
        twitterUrl: "",
        instagramUrl: "",
        linkedInUrl: "",
        youtubeUrl: "",

        parentId: "",
        brandName: "",
        appName: "",
        appDescription: "",
        bannerContactAddress: "",
        fontColor:"",
buttonText:"",
        primaryColor: "",
        secondaryColor: "",
        scaffoldColor: "",
        backgroundColor: "",


        fontFamily: "",
        gatewayPayKeyId: "",
        gatewayPayKeySecret: "",

        aboutUs: "",
        termsOfUse: "",
        privacyPolicy: "",

        logoFile: "",
        bannerFiles: "",
        certificates: "",

      }}
      validationSchema={Yup.object().shape({
        // businessName: Yup.string().max(50).required("Business Name is required"),
        // mobile: Yup.string()
        //   .matches(
        //     /^[6-9][0-9]{9}/,
        //     "Please enter valid 10 digit mobile number"
        //   )
        //   .max(10)
        //   .min(10)
        //   .required("Please enter mobile number"),
        // whatsApp: Yup.string()
        //   .matches(
        //     /^[6-9][0-9]{9}/,
        //     "Please enter valid 10 digit mobile number"
        //   )
        //   .max(10)
        //   .min(10)
        //   .required("Please enter watsApp number"),
        // helpLine: Yup.string().max(50).required("Help Line is required"),
        // privacyPolicy: Yup.string().max(10000),
        // termsOfUse: Yup.string().max(10000),
        // registeredEmail: Yup.string().max(256).required("Registered Email is required"),
        // registeredAddress: Yup.string().max(500).required("Registered Address is required"),
        // GSTN: Yup.string().matches(
        //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        //   "Please Enter Valid GST Number").max(15).min(15),
        // about: Yup.string().max(10000).required("About is required"),
        // brandName: Yup.string().max(100).required("Brand Name is required"),
        // appName: Yup.string().max(100).required("App Name is required"),
        // appDescription: Yup.string().max(500).required("App Description is required"),
        // bannerContactAddress: Yup.string().max(500).required("Banner ContactAddress is required"),
        // primaryColor: Yup.string().max(50).required("Primary Color is required"),
        // secondaryColor: Yup.string().max(50).required("Secondary Color is required"),
        // scaffoldColor: Yup.string().max(50).required("Scaffold Color is required"),
        // fontFamily: Yup.string().max(50).required("Font Family is required"),
        // backgroundColor: Yup.string().max(50).required("Background Color Name is required"),
        // aboutUs: Yup.string().max(10000).required("About Us is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("Submitting the request", addedBy, values, image, files, image2);
        try {
           console.log("ooo--------------------");
            // console.log(service.showProductPrice);
             console.log("ooo--------------------");
          await dispatch(createCustomer(addedBy, values, image, files, image2, 
            myBooleanShowContacts,myBooleanShowDistributor,myBooleanShowServiceCenters,myBooleanShowMaintenanceTroubleshooting
            ,myBooleanIsEngineeringType,ondcIntegration,showPos,myBooleanshowSubcategories,myBooleanPinchZoom,myBooleanisTaxInclusivePrice,myBooleanshowQuotationRequest,myBooleanshowBrand,myBooleanshowLargeImage,myBooleanshowSKU,myBooleanshowSqrCategory,myBooleanshowProductPrice
         
            ));
            console.log("hi------");
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("customer Created", {
            variant: "success",
          });
          dispatch(fetchCustomerList());
          navigate("/app/management/customer/view");
        } catch (err) {
          setErrors({ submit: err.response.data.message });
          setStatus({ success: false });
          setSubmitting(false);
          enqueueSnackbar("customer Create Failed", {
            variant: "failure",
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
         <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Box mb={5}>

          <Typography
            variant="h3"
            color="textPrimary"
          > 
          {state.businessName ? "Add Customer From Register Request" : "Add Customer"}

            
              {/* Add Customer */}
          </Typography>
          </Box>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Button
          component={RouterLink}
          to={state.businessName ? "/app/management/customer/register" : "/app/management/customer/view"}
          // to="/app/management/customer/view"
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
          <Card>
           <CardHeader title={state.businessName ? "Add Customer From Register Request" : "Add Customer"} />

            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.businessName && errors.businessName
                    )}
                    fullWidth
                    helperText={
                      touched.businessName && errors.businessName
                    }
                    label="Business Name"
                    name="businessName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.registeredEmail && errors.registeredEmail
                    )}
                    fullWidth
                    helperText={
                      touched.registeredEmail && errors.registeredEmail
                    }
                    label="Registered Email"
                    name="registeredEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.registeredEmail}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.registeredAddress && errors.registeredAddress
                    )}
                    fullWidth
                    helperText={
                      touched.registeredAddress && errors.registeredAddress
                    }
                    label="Registered Address"
                    name="registeredAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.registeredAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.mobile && errors.mobile
                    )}
                    fullWidth
                    helperText={
                      touched.mobile && errors.mobile
                    }
                    label="Mobile"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.whatsApp && errors.whatsApp
                    )}
                    fullWidth
                    helperText={
                      touched.whatsApp && errors.whatsApp
                    }
                    // multiline
                    // rows={4}
                    label="whats App"
                    name="whatsApp"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.whatsApp}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.helpLine && errors.helpLine
                    )}
                    fullWidth
                    helperText={
                      touched.helpLine && errors.helpLine
                    }
                    label="Help Line"
                    name="helpLine"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.helpLine}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.GSTN && errors.GSTN
                    )}
                    fullWidth
                    helperText={
                      touched.GSTN && errors.GSTN
                    }
                    label="GSTN"
                    name="GSTN"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.GSTN}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.about && errors.about
                    )}
                    fullWidth
                    helperText={
                      touched.about && errors.about
                    }
                    label="About"
                    name="about"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.about}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    value={values.paymentGateway}
                    onChange={(event, newValue) => {
                      setFieldValue("paymentGateway", newValue);
                    }}
                    disablePortal
                    id="paymentGateway"
                    options={PAYMENTGATEWAY_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.paymentGateway}
                    helperText={errors.paymentGateway}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Payment Gateway"
                          error={Boolean(touched.paymentGateway && errors.paymentGateway)}
                          helperText={touched.paymentGateway && errors.paymentGateway}
                        />
                      </>
                    )}
                  />
                </Grid>
                {values.paymentGateway === "ONLINE" ?
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      value={values.gatewayType}
                      onChange={(event, newValue) => {
                        setFieldValue("gatewayType", newValue);
                      }}
                      disablePortal
                      id="gatewayType"
                      options={PAYMENTGATEWAYTYPE_TYPE}
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      error={errors.gatewayType}
                      helperText={errors.gatewayType}
                      renderInput={(params) => (
                        <>
                          {" "}
                          <TextField {...params} label="Payment Gateway Type"
                            error={Boolean(touched.gatewayType && errors.gatewayType)}
                            helperText={touched.gatewayType && errors.gatewayType}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  :
                  <Box />
                }
                {values.paymentGateway === "ONLINE" ?
                  <Grid item xs={4}>
                    <TextField
                      error={Boolean(
                        touched.gatewayPayKeyId && errors.gatewayPayKeyId
                      )}
                      fullWidth
                      helperText={
                        touched.gatewayPayKeyId && errors.gatewayPayKeyId
                      }
                      label="GateWay KeyId"
                      name="gatewayPayKeyId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gatewayPayKeyId}
                      variant="outlined"
                    />
                  </Grid>
                  :
                  <Box />
                }
                {values.paymentGateway === "ONLINE" ?
                  <Grid item xs={4}>
                    <TextField
                      error={Boolean(
                        touched.gatewayPayKeySecret && errors.gatewayPayKeySecret
                      )}
                      fullWidth
                      helperText={
                        touched.gatewayPayKeySecret && errors.gatewayPayKeySecret
                      }
                      label="Gateway Secret"
                      name="gatewayPayKeySecret"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gatewayPayKeySecret}
                      variant="outlined"
                    />
                  </Grid>
                  :
                  <Box />
                }
          
                    <Grid item xs={12} sm={3} style={{marginLeft:"10em"}}>
                      <label>Show Contacts</label>
                    </Grid>
                    <Grid item xs={12} sm={1} style={{marginLeft:"-12em",marginTop:"-0.2em"}}>
                      <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                        checked={myBooleanShowContacts}
                        onChange={() => {
                          setMyBooleanShowContacts(!myBooleanShowContacts)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"10em"}}>
                     <label>  Show Distributor </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-11.5em",marginTop:"-0.2em"}}>
                    <input type="checkbox"  style={{
      color: "#00e676",
      width: 30,
      height: 30
    }}
                      checked={myBooleanShowDistributor}
                      onChange={() => {
                        setMyBooleanShowDistributor(!myBooleanShowDistributor)
                      }}
                    />
                  </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"0.5em"}}>
                        <label> Show Maintenance Troubleshooting </label>
                    </Grid>
                  <Grid item xs={12} sm={1}    style={{marginLeft:"-3em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                 
                      checked={myBooleanShowMaintenanceTroubleshooting}
                      onChange={() => {
                        setMyBooleanShowMaintenanceTroubleshooting(!myBooleanShowMaintenanceTroubleshooting)
                      }}
                    />
                  </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"7.5em"}}>
                      <label>   Show Service Centers </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-9.5em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                      checked={myBooleanShowServiceCenters}
                      onChange={() => {
                        setMyBooleanShowServiceCenters(!myBooleanShowServiceCenters)
                      }}
                    />
                  </Grid>
                    <Grid item xs={12} sm={3} style={{marginLeft:"9.5em"}}>
                         <label> Is EngineeringType </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-11em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={myBooleanIsEngineeringType}
                      onChange={() => {
                        setMyBooleanIsEngineeringType(!myBooleanIsEngineeringType)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{marginLeft:"6.3em"}}>
                         <label> Ondc Integration </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-8.9em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={ondcIntegration}
                      onChange={() => {
                        setOndcIntegration(!ondcIntegration)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{marginLeft:"10.8em"}}>
                         <label> Show Pos </label>
                    </Grid>
                  <Grid item xs={12} sm={1} style={{marginLeft:"-12.8em",marginTop:"-0.2em"}}>
                    <input type="checkbox"
                      style={{
                        color: "#00e676",
                        width: 30,
                        height: 30
                      }}
                      checked={showPos}
                      onChange={() => {
                        setShowPos(!showPos)
                      }}
                    />
                  </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showProductPrice </label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowProductPrice}
                    onChange={() => {
                      setMyBooleanshowProductPrice(!myBooleanshowProductPrice)
                    }}
                  />
                </Grid>
  <Grid item xs={12} sm={3} style={{ marginLeft: "5.5em" }}>
                  <label>showSubcategories</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-8em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowSubcategories}
                    onChange={() => {
                      setMyBooleanshowSubcategories(!myBooleanshowSubcategories)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>pinchZoom</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-12em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanPinchZoom}
                    onChange={() => {
                      setMyBooleanPinchZoom(!myBooleanPinchZoom)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>isTaxInclusivePrice</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanisTaxInclusivePrice}
                    onChange={() => {
                      setMyBooleanisTaxInclusivePrice(!myBooleanisTaxInclusivePrice)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "5.5em" }}>
                  <label>showQuotationRequest</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-8em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowQuotationRequest}
                    onChange={() => {
                      setMyBooleanshowQuotationRequest(!myBooleanshowQuotationRequest)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showBrand</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-12em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowBrand}
                    onChange={() => {
                      setMyBooleanshowBrand(!myBooleanshowBrand)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showLargeImage</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowLargeImage}
                    onChange={() => {
                      setMyBooleanshowLargeImage(!myBooleanshowLargeImage)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>showSKU</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-12.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowSKU}
                    onChange={() => {
                      setMyBooleanshowSKU(!myBooleanshowSKU)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "9em" }}>
                  <label>showSqrCategory</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowSqrCategory}
                    onChange={() => {
                      setMyBooleanshowSqrCategory(!myBooleanshowSqrCategory)
                    }}
                  />
                </Grid>
                


                
                {/* <Grid item xs={12} sm={3} style={{ marginLeft: "5.5em" }}>
                  <label>Show Quotation Request</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-8em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanshowQuotationRequest}
                    onChange={() => {
                      setMyBooleanShowQuotationRequest(!myBooleanShowQuotationRequest)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>Show Contacts</label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-12em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanShowContacts}
                    onChange={() => {
                      setMyBooleanShowContacts(!myBooleanShowContacts)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "10em" }}>
                  <label>  Show Distributor </label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox" style={{
                    color: "#00e676",
                    width: 30,
                    height: 30
                  }}
                    checked={myBooleanShowDistributor}
                    onChange={() => {
                      setMyBooleanShowDistributor(!myBooleanShowDistributor)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "0.5em" }}>
                  <label> Show Maintenance Troubleshooting </label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-3em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}

                    checked={myBooleanShowMaintenanceTroubleshooting}
                    onChange={() => {
                      setMyBooleanShowMaintenanceTroubleshooting(!myBooleanShowMaintenanceTroubleshooting)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "7.5em" }}>
                  <label>   Show Service Centers </label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-9.5em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanShowServiceCenters}
                    onChange={() => {
                      setMyBooleanShowServiceCenters(!myBooleanShowServiceCenters)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginLeft: "9.5em" }}>
                  <label> Is EngineeringType </label>
                </Grid>
                <Grid item xs={12} sm={1} style={{ marginLeft: "-11em", marginTop: "-0.2em" }}>
                  <input type="checkbox"
                    style={{
                      color: "#00e676",
                      width: 30,
                      height: 30
                    }}
                    checked={myBooleanIsEngineeringType}
                    onChange={() => {
                      setMyBooleanIsEngineeringType(!myBooleanIsEngineeringType)
                    }}
                  />
                </Grid> */}
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.businessLocation && errors.businessLocation
                    )}
                    fullWidth
                    helperText={
                      touched.businessLocation && errors.businessLocation
                    }
                    label="Business Location"
                    name="businessLocation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessLocation}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.website && errors.website
                    )}
                    fullWidth
                    helperText={
                      touched.website && errors.website
                    }
                    label="Website"
                    name="website"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.website}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.facebookUrl && errors.facebookUrl
                    )}
                    fullWidth
                    helperText={
                      touched.facebookUrl && errors.facebookUrl
                    }
                    label="Facebook Url"
                    name="facebookUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.facebookUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.youtubeUrl && errors.youtubeUrl
                    )}
                    fullWidth
                    helperText={
                      touched.youtubeUrl && errors.youtubeUrl
                    }
                    label="Youtube Url"
                    name="youtubeUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.youtubeUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.twitterUrl && errors.twitterUrl
                    )}
                    fullWidth
                    helperText={
                      touched.twitterUrl && errors.twitterUrl
                    }
                    label="Twitter Url"
                    name="twitterUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.twitterUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.instagramUrl && errors.instagramUrl
                    )}
                    fullWidth
                    helperText={
                      touched.instagramUrl && errors.instagramUrl
                    }
                    label="Instagram Url"
                    name="instagramUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.instagramUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.linkedInUrl && errors.linkedInUrl
                    )}
                    fullWidth
                    helperText={
                      touched.linkedInUrl && errors.linkedInUrl
                    }
                    label="LinkedIn Url"
                    name="linkedInUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.linkedInUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.parentId && errors.parentId
                    )}
                    fullWidth
                    helperText={
                      touched.parentId && errors.parentId
                    }
                    label="Parent Id"
                    name="parentId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.parentId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.brandName && errors.brandName
                    )}
                    fullWidth
                    helperText={
                      touched.brandName && errors.brandName
                    }
                    label="Brand Name"
                    name="brandName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brandName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.appName && errors.appName
                    )}
                    fullWidth
                    helperText={
                      touched.appName && errors.appName
                    }
                    label="App Name"
                    name="appName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.appName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.appDescription && errors.appDescription
                    )}
                    fullWidth
                    helperText={
                      touched.appDescription && errors.appDescription
                    }
                    label="App Description"
                    name="appDescription"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.appDescription}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={(
                      touched.bannerContactAddress && errors.bannerContactAddress
                    )}
                    fullWidth
                    helperText={
                      touched.bannerContactAddress && errors.bannerContactAddress
                    }
                    label="Banner Contact Address"
                    name="bannerContactAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.bannerContactAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.primaryColor && errors.primaryColor
                    )}
                    fullWidth
                    helperText={
                      touched.primaryColor && errors.primaryColor
                    }
                    label="Primary Color"
                    name="primaryColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.primaryColor}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.secondaryColor && errors.secondaryColor
                    )}
                    fullWidth
                    helperText={
                      touched.secondaryColor && errors.secondaryColor
                    }
                    label="Secondary Color"
                    name="secondaryColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.secondaryColor}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      touched.scaffoldColor && errors.scaffoldColor
                    )}
                    fullWidth
                    helperText={
                      touched.helpLine && errors.scaffoldColor
                    }
                    label="Scaffold Color"
                    name="scaffoldColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.scaffoldColor}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.backgroundColor && errors.backgroundColor
                    )}
                    fullWidth
                    helperText={
                      touched.backgroundColor && errors.backgroundColor
                    }
                    label="Background Color"
                    name="backgroundColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.backgroundColor}
                    variant="outlined"
                  />
                </Grid>
                  <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.fontColor && errors.fontColor
                    )}
                    fullWidth
                    helperText={
                      touched.fontColor && errors.fontColor
                    }
                    label="font Color"
                    name="fontColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.backgroundColor}
                    variant="outlined"
                  />
                   
                </Grid>
 <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.buttonText && errors.buttonText
                    )}
                    fullWidth
                    helperText={
                      touched.buttonText && errors.buttonText
                    }
                    label="button text"
                    name="buttonText"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.buttonText}
                    variant="outlined"
                  />
                   </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    fullWidth
                    value={values.fontFamily}
                    onChange={(event, newValue) => {
                      setFieldValue("fontFamily", newValue);
                    }}
                    disablePortal
                    id="fontFamily"
                    options={FONTFAMILY_TYPE}
                    required
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    error={errors.fontFamily}
                    helperText={errors.fontFamily}
                    renderInput={(params) => (
                      <>
                        {" "}
                        <TextField {...params} label="Font Family"
                          error={Boolean(touched.fontFamily && errors.fontFamily)}
                          helperText={touched.fontFamily && errors.fontFamily}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.aboutUs && errors.aboutUs
                    )}
                    fullWidth
                    helperText={
                      touched.aboutUs && errors.aboutUs
                    }
                    multiline
                    rows={4}
                    label="About Us"
                    name="aboutUs"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.aboutUs}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.privacyPolicy && errors.privacyPolicy
                    )}
                    fullWidth
                    helperText={
                      touched.privacyPolicy && errors.privacyPolicy
                    }
                    multiline
                    rows={4}
                    label="Privacy Policy"
                    name="privacyPolicy"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.privacyPolicy}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.termsOfUse && errors.termsOfUse
                    )}
                    fullWidth
                    helperText={
                      touched.helpLine && errors.termsOfUse
                    }
                    multiline
                    rows={4}
                    label="Terms Of Use"
                    name="termsOfUse"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.termsOfUse}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography>Logo File</Typography>
                  <input type="file" required onChange={handleImage} />
                </Grid>
                <Grid item xs={4}>
                  <Typography>Banner File</Typography>
                  <input type="file" multiple onChange={uploadFileHandler} ref={ref} />

                </Grid>
                <Grid item xs={4}>
                  <Typography>Certificates File</Typography>
                  <input type="file" onChange={handleImage2} />
                </Grid>
                <ConfirmBox
                  open={open}
                  closeDialog={() => setOpen(false)}
                  title={iLength}
                />
              </Grid>
            </CardContent>
          </Card>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginBottom: '5em' }}
            >
              Submit
            </Button>
            <Button style={{ marginLeft: '1em', marginBottom: '5em' }} color="secondary" variant='contained' onClick={onClick}>
              Cancel</Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CustomerCreateForm.propTypes = {
  className: PropTypes.string,
  cid: PropTypes.number,
  onClick: PropTypes.func,
};

export default CustomerCreateForm;



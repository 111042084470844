import { CREATE_ATTRIBUTE, DELETE_ATTRIBUTE } from 'src/constants/services';
import axios from 'src/utils/axios';


class MgmtAttribute {

  createAttribute = (newAttribute) => new Promise((resolve, reject) => {
    // console.log("newAttribute: ", newAttribute)
    

    axios.post(CREATE_ATTRIBUTE, newAttribute)
      .then((response) => {
        // console.log("MgmtAttribute(Response).createAttibute():" + JSON.stringify(response));

        if (response.data.data.attibutes) {
          let attributes = response.data.data.attibutes;
          resolve(attributes);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("MgmtAttribute.createAttibute (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })


  deleteAttribute = (attribute) => new Promise((resolve, reject) => {
    console.log("deleteAttribute: ", attribute)

    axios.post(DELETE_ATTRIBUTE, attribute)
      .then((response) => {
        // console.log("MgmtAttribute(Response).deleteAttribute():" + JSON.stringify(response));

        if (response.data) {
          let attributeD = response.data;
          resolve(attributeD);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        // console.log("MgmtAttribute.deleteAttribute (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })






  updateService = (serviceInfo) => new Promise((resolve, reject) => {

    const payload = serviceInfo;
    const formData = new FormData()
    // formData.append('categoryData',{"customerId":newService.customerId})
    formData.append('categoryData', `{"customerId":${serviceInfo.customerId},"categoryId":${serviceInfo.categoryId},
     "name":"${serviceInfo.name}"}`);
    // formData.append('name',newService.name)
    // formData.append('logoFile', newService)


    axios.put('/api/admin/catalog/category', formData)
      .then((response) => {
        console.log("MgmtService(Response):" + JSON.stringify(response));

        if (response.data.data.category) {
          let services = response.data.data.category;
          resolve(services);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

  fetchAttribute = (cid) => new Promise((resolve, reject) => {
    // const cusid = (userType === "SUPER_ADMIN") ? cid : customerId;
    let searchUrl = "/api/admin/catalog/products/attributes";
    searchUrl += (cid) ? `?&customerId=${cid}` : "";
    axios
      .get(searchUrl)
      .then((response) => {
        // console.log("MgmtAttribute(fetchInvoice->Response): 1", response);
        if (response?.data?.data) {
          let attribute = response?.data?.data;
          // console.log("MgmtUser(fetchUsers)", users);
          resolve(attribute);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("MgmtUser.createUser (Response Error):" + JSON.stringify(error));
        reject(error);
      });
  })

}


const mgmtAttribute = new MgmtAttribute();

export default mgmtAttribute;

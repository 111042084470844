import { CustomerCredentialsForm, CustomerForm } from "src/interface/customerFormIf";
import { CustomerLogoAndBannerUpload } from "src/interface/customerIf";
import mgmtCustomerForm from "src/services/mgmtCustomerForm";

export const PROJECT_IMAGE_UPLOAD_SUCCESS = "@project-image/upload-success";
export const CUSTOMER_INFO = '@customer/info';
export const CUSTOMER_CREATE = '@customer/create';
export const CUSTOMER_CREATE_SUCCESS = '@customer/create-success';
export const CUSTOMER_CREATE_FAILURE = '@news/create-failure';
export const CUSTOMER_UPDATE = '@customer/update';
export const CUSTOMER_UPDATE_SUCCESS = '@customer/update-success';
export const CUSTOMER_UPDATE_FAILURE = '@customer/update-failure';

export const CUSTOMERLOGOBANNERUPLOAD_CREATE = '@customerlogobannerupload/create';
export const CUSTOMERLOGOBANNERUPLOAD_CREATE_SUCCESS = '@customerlogobannerupload/create-success';
export const CUSTOMERLOGOBANNERUPLOAD_CREATE_FAILURE = '@customerlogobannerupload/create-failure';

export const CUSTOMERPRIVACYPOLICY_UPDATE = '@customerprivacypolicy/update';
export const CUSTOMERPRIVACYPOLICY_UPDATE_SUCCESS = '@customerprivacypolicy/update-success';
export const CUSTOMERPRIVACYPOLICY_UPDAE_FAILURE = '@customerprivacypolicy/update-failure';

export const CUSTOMERDETAILS_UPDATE = '@customerdetails/update';
export const CUSTOMERDETAILS_UPDATE_SUCCESS = '@customerdetails/update-success';
export const CUSTOMERDETAILS_UPDATE_FAILURE = '@customerdetails/update-failure';

export const CUSTOMERJSONDOWNLOAD_UPDATE = '@customerjsondownload/update';
export const CUSTOMERJSONDOWNLOAD_UPDATE_SUCCESS = '@customerjsondownload/update-success';
export const CUSTOMERJSONDOWNLOAD_UPDATE_FAILURE = '@customerjsondownload/update-failure';

export const CUSTOMERPAYMENTDETAILS_UPDATE = '@customerpaymentdetails/update';
export const CUSTOMERPAYMENTDETAILS_UPDATE_SUCCESS = '@customerpaymentdetails/update-success';
export const CUSTOMERPAYMENTDETAILS_UPDATE_FAILURE = '@customerpaymentdetails/update-failure';

export const CUSTOMERBRANDDETAILS_UPDATE = '@customerbranddetails/update';
export const CUSTOMERBRANDDETAILS_UPDATE_SUCCESS = '@customerbranddetails/update-success';
export const CUSTOMERBRANDDETAILS_UPDATE_FAILURE = '@customerbranddetails/update-failure';

export const FETCHCUSTOMER_INFO = '@customerlist/info';
export const FETCHCUSTOMER_INFO_SUCCESS = '@customerlist/info-success';
export const FETCHCUSTOMER_INFO_FAILURE = '@customerlist/info-failure';

export const FETCHADMINCUSTOMER_INFO = '@customeradminlist/info';
export const FETCHADMINCUSTOMER_INFO_SUCCESS = '@customeradminlist/info-success';
export const FETCHADMINCUSTOMER_INFO_FAILURE = '@customeradminlist/info-failure';

export const FETCHADMINCUSTOMERPAYMENT_INFO = '@customerpayment/info';
export const FETCHADMINCUSTOMERPAYMENT_INFO_SUCCESS = '@customerpayment/info-success';
export const FETCHADMINCUSTOMERPAYMENT_INFO_FAILURE = '@customerpayment/info-failure';

export const FETCH_DELIVERY_CHARGE = '@deliverychargelist/info';
export const FETCH_DELIVERY_CHARGE_SUCCESS = '@deliverychargelist/info-success';
export const FETCH_DELIVERY_CHARGE_FAILURE = '@deliverychargelist/info-failure';
export function createCustomer(userId, customerInfo, image, files, image2, myBooleanShowContacts, myBooleanShowDistributor, myBooleanShowServiceCenters, myBooleanShowMaintenanceTroubleshooting, myBooleanIsEngineeringType, ondcIntegration, showPos, myBooleanshowSubcategories, myBooleanPinchZoom, myBooleanisTaxInclusivePrice, myBooleanshowQuotationRequest, myBooleanshowBrand, myBooleanshowLargeImage, myBooleanshowSKU, myBooleanshowSqrCategory, myBooleanshowProductPrice) {
  return async (dispatch) => {
    let newCustomer = new CustomerForm(userId, customerInfo);
    newCustomer.addedBy = userId;
    
    console.log("createCustomer() - Initial customerInfo:", JSON.stringify(customerInfo));
    console.log("createCustomer() - Initial newCustomer:", JSON.stringify(newCustomer));

    try {
      dispatch({ type: CUSTOMER_CREATE });
      
      console.log("createCustomer() - Before API call");
      
      const customer = await mgmtCustomerForm.createCustomer(
        newCustomer, 
        image, 
        files, 
        image2, 
        myBooleanShowContacts, 
        myBooleanShowDistributor, 
        myBooleanShowServiceCenters, 
        myBooleanShowMaintenanceTroubleshooting, 
        myBooleanIsEngineeringType, 
        ondcIntegration, 
        showPos, 
        myBooleanshowSubcategories, 
        myBooleanPinchZoom, 
        myBooleanisTaxInclusivePrice, 
        myBooleanshowBrand, 
        myBooleanshowLargeImage, 
        myBooleanshowSKU, 
        myBooleanshowSqrCategory, 
        myBooleanshowProductPrice,
        myBooleanshowProductPrice,
        myBooleanshowSubcategories,
       
 myBooleanisTaxInclusivePrice,
 myBooleanshowQuotationRequest,
 myBooleanshowBrand,
 myBooleanshowLargeImage,
 myBooleanshowSKU,
 myBooleanshowSqrCategory
      );
      
      console.log("createCustomer() - After API call, customer:", JSON.stringify(customer));
      
      dispatch({
        type: CUSTOMER_CREATE_SUCCESS,
        payload: {
          customer
        }
      });
      
      console.log("createCustomer() - Dispatching CUSTOMER_CREATE_SUCCESS");
      
    } catch (error) {
      console.error("createCustomer() - Error:", error);
      dispatch({ type: CUSTOMER_CREATE_FAILURE });
      throw error;
    }
  };
}

// export function createCustomer(userId, customerInfo, image, files, image2, myBooleanShowContacts,myBooleanShowDistributor,myBooleanShowServiceCenters,myBooleanShowMaintenanceTroubleshooting
//             ,myBooleanIsEngineeringType,myBooleanShowQuotationRequest,ondcIntegration,showPos,myBooleanshowSubcategories,myBooleanpinchZoom,myBooleanisTaxInclusivePrice,myBooleanshowQuotationRequest,myBooleanshowBrand,myBooleanshowLargeImage,myBooleanshowSKU,myBooleanshowSqrCategory,myBooleanshowProductPrice) {
//   return async (dispatch) => {
//     let newCustomer = new CustomerForm(userId, customerInfo);
//     newCustomer.addedBy = userId;
//     // console.log("createCustomer():" + JSON.stringify(customerInfo) + " " + JSON.stringify(newCustomer));
//     try {
//       dispatch({ type: CUSTOMER_CREATE });
//       const customer = await mgmtCustomerForm.createCustomer(newCustomer, image, files, image2, myBooleanShowContacts,myBooleanShowDistributor,myBooleanShowServiceCenters,myBooleanShowMaintenanceTroubleshooting
//             ,myBooleanIsEngineeringType,myBooleanShowQuotationRequest,ondcIntegration,showPos,myBooleanshowSubcategories,myBooleanpinchZoom,myBooleanisTaxInclusivePrice,myBooleanshowQuotationRequest,myBooleanshowBrand,myBooleanshowLargeImage,myBooleanshowSKU,myBooleanshowSqrCategory,myBooleanshowProductPrice);
//       // console.log("createCustomer()(after):" + JSON.stringify(customer));
//       dispatch({
//         type: CUSTOMER_CREATE_SUCCESS,
//         payload: {
//           customer
//         }
//       });
//     } catch (error) {
//       dispatch({ type: CUSTOMER_CREATE_FAILURE });
//       throw error;
//     }
//   };
// }

export function customerJsonDownload(title,value) {
  return async (dispatch) => {
  
    // console.log("createCustomer():" + JSON.stringify(customerInfo) + " " + JSON.stringify(newCustomer));
    try {
      dispatch({ type: CUSTOMERJSONDOWNLOAD_UPDATE });
      const jsonData = await mgmtCustomerForm.customerJsonDownload(title,value);
      // console.log("createCustomer()(after):" + JSON.stringify(customer));
      dispatch({
        type: CUSTOMERJSONDOWNLOAD_UPDATE_SUCCESS,
        payload: {
          jsonData
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMERJSONDOWNLOAD_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function createPaymentUpdate(customerPaymentInfo) {
  // console.log("customerPaymentInfo",customerPaymentInfo);
  return async (dispatch) => {
    let paymentUpdate = new CustomerCredentialsForm(customerPaymentInfo);
  
    // console.log("createCustomer():" + JSON.stringify(customerInfo) + " " + JSON.stringify(newCustomer));
    try {
      dispatch({ type: CUSTOMERPAYMENTDETAILS_UPDATE });
      const customerPaymentUpdate = await mgmtCustomerForm.customerPaymentDetailsUpdate(paymentUpdate);
      // console.log("createCustomer()(after):" + JSON.stringify(customer));
      dispatch({
        type: CUSTOMERPAYMENTDETAILS_UPDATE_SUCCESS,
        payload: {
          customerPaymentUpdate
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMERPAYMENTDETAILS_UPDATE_FAILURE });
      throw error;
    }
  };
}


export function logoAndBannerUpload(customerlogoInfo) {
  return async (dispatch) => {
    let newCustomerLogo = new CustomerLogoAndBannerUpload(customerlogoInfo);
    // console.log("logoandbanner():" + JSON.stringify(customerlogoInfo) + " " + JSON.stringify(newCustomerLogo));
    try {
      dispatch({ type: CUSTOMERLOGOBANNERUPLOAD_CREATE });
      const customerlogo = await mgmtCustomerForm.customerBannerAndLogoUpload(newCustomerLogo);
      // console.log("logoandbanner()(after):" + JSON.stringify(customerlogo));
      dispatch({
        type: CUSTOMERLOGOBANNERUPLOAD_CREATE_SUCCESS,
        payload: {
          customerlogo
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMERLOGOBANNERUPLOAD_CREATE_FAILURE, });
      throw error;
    }
  };
}

export function customerPrivacyPolicyUpdate(privacyPolicy) {
  return async (dispatch) => {
    try {
      dispatch({ type: CUSTOMERPRIVACYPOLICY_UPDATE });
      const cusPrivacyPolicy = await mgmtCustomerForm.customerPrivacyPolicyUpdate(privacyPolicy);
      // console.log("privacypolicyupdate()(after):" + JSON.stringify(customerlogo));
      dispatch({
        type: CUSTOMERPRIVACYPOLICY_UPDATE_SUCCESS,
        payload: {
          cusPrivacyPolicy
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMERPRIVACYPOLICY_UPDAE_FAILURE, });
      throw error;
    }
  };
}

export function customerBrandDetailsUpdate(customerDetails) {
  return async (dispatch) => {
    try {
      dispatch({ type: CUSTOMERBRANDDETAILS_UPDATE });
      const customerInfoDetails = await mgmtCustomerForm.customerBrandDetailsUpdate(customerDetails);
      // console.log("customerBrandDetailsUpdate()(after):" + JSON.stringify(customerlogo));
      dispatch({
        type: CUSTOMERBRANDDETAILS_UPDATE_SUCCESS,
        payload: {
          customerInfoDetails
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMERBRANDDETAILS_UPDATE_FAILURE, });
      throw error;
    }
  };
}

export function customerDetailsUpdate(customerDetails, myBooleanShowContacts,myBooleanShowDistributor,
          myBooleanShowServiceCenters
          ,myBooleanShowMaintenanceTroubleshooting
            ,myBooleanIsEngineeringType,
            ondcIntegration,showPos,myBooleanshowSubcategories,
            myBooleanPinchZoom,myBooleanisTaxInclusivePrice,
            myBooleanshowQuotationRequest,myBooleanshowBrand,
            myBooleanshowLargeImage,myBooleanshowSKU,
            myBooleanshowSqrCategory,myBooleanshowProductPrice) {
  return async (dispatch) => {
    try {
      dispatch({ type: CUSTOMERDETAILS_UPDATE });
      const customerInfoDetails = await mgmtCustomerForm.customerDetailsUpdate(customerDetails, myBooleanShowContacts,myBooleanShowDistributor,
          myBooleanShowServiceCenters
          ,myBooleanShowMaintenanceTroubleshooting
            ,myBooleanIsEngineeringType,
            ondcIntegration,showPos,myBooleanshowSubcategories,
            myBooleanPinchZoom,myBooleanisTaxInclusivePrice,
            myBooleanshowQuotationRequest,myBooleanshowBrand,
            myBooleanshowLargeImage,myBooleanshowSKU,
            myBooleanshowSqrCategory,myBooleanshowProductPrice);
      // console.log("customerDetailsUpdate()(after):" + JSON.stringify(customerlogo));
      dispatch({
        type: CUSTOMERDETAILS_UPDATE_SUCCESS,
        payload: {
          customerInfoDetails
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMERDETAILS_UPDATE_FAILURE, });
      throw error;
    }
  };
}

export function fetchCustomerList() {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHCUSTOMER_INFO });
      const customerInfoList = await mgmtCustomerForm.fetchCustomerList();
      // console.log("customerInfoList Action", customerInfoList);
      dispatch({
        type: FETCHCUSTOMER_INFO_SUCCESS,
        payload: {
          customerInfoList,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCHCUSTOMER_INFO_FAILURE });
      throw error;
    }
  };
}

export function fetchAdminCustomerList(cid) {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHADMINCUSTOMER_INFO });
      const customeradminInfoList = await mgmtCustomerForm.fetchAdminCustomerList(cid);
      // console.log("customeradminInfoList Action", customeradminInfoList);
      dispatch({
        type: FETCHADMINCUSTOMER_INFO_SUCCESS,
        payload: {
          customeradminInfoList,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCHADMINCUSTOMER_INFO_FAILURE });
      throw error;
    }
  };
}

export function fetchPaymentCredentialsInfo(cid) {

  return async (dispatch) => {
    try {
      dispatch({ type: FETCHADMINCUSTOMERPAYMENT_INFO });
      const customerpaymentInfoList = await mgmtCustomerForm.fetchPaymentInfoList(cid);
      // console.log("customeradminInfoList Action", customeradminInfoList);
      dispatch({
        type: FETCHADMINCUSTOMERPAYMENT_INFO_SUCCESS,
        payload: {
          customerpaymentInfoList,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCHADMINCUSTOMERPAYMENT_INFO_FAILURE });
      throw error;
    }
  };
}

export function DeliveryChargesCreate(values) {
  console.log(`mgmtCustomerCreateAction.DeliveryChargesCreate() :: values: `, values);
  return async (dispatch) => {
    try {
      const deliveryCharges = await mgmtCustomerForm.addDeliveryCharges(values);
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCHADMINCUSTOMERPAYMENT_INFO_FAILURE });
      throw error;
    }
  };
}

export function GetDeliveryChargeList(cid) {
  console.log(`mgmtCustomerCreateAction.GetDeliveryChargeList() :: cid: `, cid);
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_DELIVERY_CHARGE });
      const deliveryChargeList = await mgmtCustomerForm.fetchDeliveryChargeList(cid);
      // console.log("deliveryChargeList Action", deliveryChargeList);
      dispatch({
        type: FETCH_DELIVERY_CHARGE_SUCCESS,
        payload: {
          deliveryChargeList,
        },
      });
    } catch (error) {
      // console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: FETCH_DELIVERY_CHARGE_FAILURE });
      throw error;
    }
  };
}







import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInquiryData } from '../../../actions/mgmtRegisterRequest';
import Results from './Result';
import { ClassNames } from '@emotion/react';
import MperitoCard from 'src/components/MperitoCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
}));

function CustomerRegisterRequest() {
    const classes = useStyles();
  const dispatch = useDispatch();
  const inquiryData = useSelector((state) => state.inquiry.data);

  useEffect(() => {
    dispatch(fetchInquiryData());
  }, [dispatch]);

 // Add console logs to check the inquiryData
  console.log("Inquiry Data:", inquiryData); // Log the entire inquiryData
  console.log("Inquiry Data Length:", inquiryData.length ?? 0); // Log the length of data
const adminList = inquiryData || [];
  console.log("adminList:",adminList); // Log the length of data


return (
    <div>
    <Page
      className={classes.root}
      title="Customer List"
    >
       <Container maxWidth={false}>
         <Header />
                <br />
                <br />
         <MperitoCard title={`Register Request Lists`}>
                    <Results adminList={adminList} />
          </MperitoCard>
     </Container>
    </Page>
    
   
    
    </div>
  );
}

export default CustomerRegisterRequest;
  {/* {inquiryData.data && inquiryData.data.length > 0 ? (
        <ul>
          {inquiryData.data.map((business) => (
            <li key={business.businessId}>
              <p><strong>Business Name:</strong> {business.businessName}</p>
              <p><strong>Email:</strong> {business.email}</p>
              <p><strong>Mobile:</strong> {business.mobile}</p>
              <p><strong>Business Type:</strong> {business.businessType}</p>
              <p><strong>Status:</strong> {business.status}</p>
              <p><strong>Requested From:</strong> {business.requestedFrom}</p>
              <p>--------------------------------</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No registered businesses found.</p>
      )} */}
import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  SvgIcon,IconButton,
} from '@mui/material';


function Results({ className, adminList, customerId, ...rest }) {
  const navigate = useNavigate();
  
  const columns = [
    { field: 'customerId', headerName: 'Customer Id', width: 100 },
    { field: 'adminId', headerName: 'Admin Id', width: 100 },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: (params) => {
     const userTy = params.userType
      const isDisabled = userTy === 'ADMIN' || userTy === 'SUPPORT';
      return (
        <div className="cellAction">
          <Tooltip title="Renew Password">
            <IconButton
            disable = {isDisabled}
              onClick={() => {
                navigate('/app/management/admin/renew/password', { state: params.row });
              }
              }
            >
              <SvgIcon fontSize="small">
                <EditIcon/>
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'mobile', headerName: 'Mobile', width: 100 },
  { field: 'userType', headerName: 'User Type', width: 100 },
  { field: 'isEmailValidated', headerName: 'Is EmailValidated', width: 100 },
  { field: 'isActive', headerName: 'Is Active', width: 100 },
]

  return (

    <Box sx={{ height: 400, width: '100%', padding: 2 }}>
      <DataGrid
        rows={adminList}
        columns={columns}
        getRowId={(row) => row.adminId}
        // checkboxSelection={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  adminList: PropTypes.array
};

Results.defaultProps = {
  adminList: []
};

export default Results;



















